import {useState, useEffect, useCallback} from "react";
import general from "../../general";
import {useMount, useToggle, useLocalStorageState, useLockFn} from "ahooks";
import {CloseOutlined, DownOutlined, EyeInvisibleOutlined, EyeOutlined} from "@ant-design/icons";
import {prod, url} from "../../env";
import {Link, useHistory} from "react-router-dom";
import useActiveState from "../../lib/useActiveState";
import Dialog from "../../lib/Dialog";
import URI from "urijs";
import MobileDetect from "mobile-detect";
import {NavLink} from "../../lib/Navigation";
import useUrlState from "@ahooksjs/use-url-state";
import moment from "moment";
import {LeftOutlined} from "@ant-design/icons";

const mobileDetect = new MobileDetect(navigator.userAgent);
export default props => {
  const [params, setParams] = useUrlState({}, {navigateMode: 'replace'});
  const history = useHistory();
  const {aplus_queue} = window;
  const [app] = useActiveState('app');

  const [datas, setDatas] = useLocalStorageState('users-0804', !prod ? [
    {username: 'ceshi2', password: 'maiyou123tyc'},
    {username: 'yang004', password: '123456'},
  ] : []);
  const [focus, setFocus] = useState('');
  const [password, {toggle: togglePassword}] = useToggle(true);
  const [data, setData] = useState(datas.length > 0 ? {...datas[0]} : {});
  // const [agreement, setAgreement] = useLocalStorageState('agreement', false);
  const [agreement, setAgreement] = useState(true);
  const [loging, setLoging] = useState(false);
  const [user, setUser] = useActiveState('user');
  const [agent] = useActiveState('agent');

  const [gameId, setGameid] = useActiveState('gameId');
  const [username, setUsername] = useActiveState('username');
  const [token, setToken] = useActiveState('token');
  const [gameInfo] = useActiveState('gameInfo');
  const login = () => {
    if (!data.username) {
      Dialog.error('请输入用户名');
      return;
    }
    if (!data.password) {
      Dialog.error('请输入密码');
      return;
    }
    if (!agreement) {
      // Dialog.error('请阅读下方的协议');
      // Dialog.error('咪噜游戏:请先阅读并同意《用户协议》和《隐私政策》');
      Dialog.info('请先阅读并同意《用户协议》和《隐私政策》');
      return;
    }
    if (loging) {
      Dialog.error(loging);
      return;
    }
    setLoging(true);
    Dialog.loading();
    general.axios.post('/user/user/login', data)
        .finally(() => {
          setLoging(false);
          Dialog.close();
        })
        .then(res => {
          // Dialog.info("login.then方法");
          let {status, data: result} = res.data;
          // Dialog.info(status.succeed);
          if (!status.succeed) {
            Dialog.error(status.error_desc);
            if(status.error_code == '1046') {
              setTimeout(()=>{
                window.history.back();
              }, 1000);
            }
          } else {
            // localStorage.setItem('user_id', result.user_id);
            localStorage.setItem('username', data.username);
            localStorage.setItem('token', result.token);
            let tmp = datas.filter(item => item.username !== data.username);
            tmp.unshift(data);
            setDatas(tmp);
            // load_load()
            // Dialog.success('登录成功', () => general.goBack(true));
            let hrefUrl = URI('/iframenogen')
              .addQuery('url', URI(gameInfo.cloudappIndexUrl)
              .addQuery('gameid', gameId)
              // .addQuery('userid', localStorage.getItem('user_id'))
              .addQuery('username', localStorage.getItem('username'))
              .addQuery('token', localStorage.getItem('token'))
              .addQuery('gameicon', gameInfo.game_image?.thumb)
              .addQuery('gamename', gameInfo.game_name)
              .addQuery('nameremark', gameInfo.nameRemark)
              .addQuery('location_url', encodeURIComponent(window.location.origin))
              .addQuery('title', "aaaaa")
              .addQuery('gamefullscreen', 1)
            )
            .toString();
            history.push(hrefUrl)
            // window.location.href = hrefUrl;

            // URLSearchParams.URLSearchParams
          }
        })
        .catch(err => {
          Dialog.error(err.message);
        });
  }

  // const load_load = useLockFn(async () => {
  //   await general.axios.post('/user/user/getMemberInfo')
  //     .finally(() => {
  //       // Dialog.info("getMemberInfo.then方法");
  //     })
  //     .then(response => {
  //       // Dialog.info("getMemberInfo.then方法");
  //       let {status, data} = response.data;
  //       // Dialog.info(status.succeed);
  //       if (!status.succeed) {

  //       } else {
  //         setUser({isLogin: true, ...data.member_info});
  //       }
  //     })
  //     .catch(err => {
  //         Dialog.error(err.message);
  //       });
  // })

  const updateAgreement = () => {
    let a = agreement == true ? false : (agreement == false ? true : false);
    setAgreement(a);
  }

  // const [startState, setStartState] = useLocalStorageState('startState', true);
  // const [isIndexNoticeDatePop, setIsIndexNoticeDatePop] = useLocalStorageState('index-notice', []); //活动的每日弹框状态

  // useEffect(()=>{
  //   let asIs = gameId ?? localStorage.getItem('gameId');
  //   let today = asIs + moment().format('YYYY-MM-DD');

  //   console.log('jinlaile');
  //   // console.log(startState);
  //   //进入云游   gamefullscreen:'1'
  //   if(gameId && userName && token && startState && window.navigator.standalone && isIndexNoticeDatePop != 1) {
  //     setIsIndexNoticeDatePop(1);
  //     // window.location.href="https://sdk2.wakaifu.com/ysy?gameid=" + gameId + "&username=" + userName + "&token=" + token + "&userid=11692250&origin=react&location_url=" + encodeURIComponent(window.location.origin);
  //     let hrefUrl = URI('/iframenogen')
  //       .addQuery('url', URI(gameInfo.cloudappIndexUrl)
  //       .addQuery('gameid', gameId)
  //       // .addQuery('userid', localStorage.getItem('user_id'))
  //       .addQuery('username', userName)
  //       .addQuery('token', token)
  //       .addQuery('gamefullscreen', 1)
  //       .addQuery('location_url', encodeURIComponent(window.location.origin))
  //       )
  //       .toString();

  //     history.push(hrefUrl)
  //     // window.location.href = hrefUrl;
  //   }
  //   console.log('quit');
  // }, [gameId, userName, token, startState]);

  //这是监听云游信息
  // useEffect(()=>{
  //   window.addEventListener('message', function(event) {
  //     var result = event.data;
  //     if(result.type == 'exitCloudApp') {
  //       console.log(result);
  //       console.log("退出了~清东西了");
  //       setStartState(false);
  //       setUserName(userName = '');
  //       setToken(token = '');
  //       localStorage.setItem('username', '');
  //       localStorage.setItem('token', '');
  //     }
  //   });
  // });

  return <div className="account-login">
    <div className="body">
      <div className="logo">
        <img src={app.applogo} alt=""/>
      </div>
      <div className="text">
        <div className="name">
          {app.appname}
        </div>
        <div className="nameRemark">
          <span>
            {gameInfo?.nameRemark}
          </span>
        </div>
      </div>
      <div className="title">
        密码登录
      </div>
      <div className="input-item">
        {/* <div className="label">账号</div> */}
        <div className="input">
          <input name="username" type="text" value={data.username} placeholder="请输入账号/手机号"
                 autoComplete="off"
                 onFocus={e => setFocus('username')}
                 onChange={e => setData({...data, username: e.target.value})}
          />
          {focus == 'username' && data.username
              ? <CloseOutlined
                  className="assist close"
                  onClick={() => setData({...data, username: ''})}
              />
              : null}
          {/* {focus == 'username' && data.username
              ? <CloseOutlined
                  className="assist close"
                  onClick={() => setData({...data, username: ''})}
              />
              : null}
          <div className="assist select">
            <DownOutlined className="select"/>
            <select onChange={e => {
              setData(e.target.value != '' ? datas[parseInt(e.target.value)] : {username: '', password: ''})
            }}>
              <option value="">手动输入</option>
              {datas.map((item, index) => <option value={index} selected={item.username == data.username}>{item.username}</option>)}
            </select>
          </div> */}
        </div>
      </div>
      <div className="input-item">
        {/* <div className="label">密码</div> */}
        <div className="input">
          <input name="password" type={password ? 'password' : 'text'} value={data.password} placeholder="请输入密码"
                 onFocus={e => setFocus('password')}
                 onChange={e => setData({...data, password: e.target.value})}
          />
          {focus == 'password' && data.password
              ? <CloseOutlined
                  className="assist close"
                  onClick={() => setData(data => ({...data, password: ''}))}/>
              : null}
          {/* {focus == 'password' && data.password
              ? <CloseOutlined
                  className="assist close"
                  onClick={() => setData(data => ({...data, password: ''}))}/>
              : null}
          <div className="assist password" onClick={() => togglePassword()}>
            {password ? <EyeInvisibleOutlined/> : <EyeOutlined/>}
          </div> */}
        </div>
      </div>
      <div className={"operate clearfix" + (app?.platformName != 'milu' ? (app?.platformName == 'huizhua' ? ' huizhua ' : ' barter') : ' ')}>
        {/* <Link to={"/auth?gameid=" + gameInfo.maiyou_gameid} className="item">验证码登录</Link> */}
        <Link onClick={() => general.history.goBack()} className="item">验证码登录</Link>
        <Link to={"/register?gameid=" + gameInfo.maiyou_gameid} className="item" style={{paddingLeft:'.3rem'}}>快速注册</Link>
      </div>
      <div className={'btn ' + (loging ? 'disable' : '') + (app?.platformName != 'milu' ? (app?.platformName == 'huizhua' ? ' huizhua ' : ' barter') : ' ')} onClick={()=>login()}>登录</div>
    </div>
    <div className={"account-agreement" + (app?.platformName != 'milu' ? (app?.platformName == 'huizhua' ? ' huizhua ' : ' barter') : ' ')}>
    <label>
        <span className={'agreement' + (agreement ? ' checked' : '')}  onClick={updateAgreement}/>
        登录注册代表您同意
        <NavLink to={(() => {
            let url = URI('//api.gamebox.i52wan.com/userAgreement')
                .addQuery('title', '用户协议')
                .addQuery('channel', agent)
                .addQuery('device_type', (mobileDetect.os() == 'iOS'?10:20))
                .toString();
              return url;
            })()}
            iframe={{title: '《用户协议》'}}
            user={false}
          >《用户协议》</NavLink>
        <NavLink to={(() => {
            let url = URI('//api.gamebox.i52wan.com/privacyPolicy')
                .addQuery('title', '隐私政策')
                .addQuery('channel', agent)
                .addQuery('device_type', (mobileDetect.os() == 'iOS'?10:20))
                .toString();
              return url;
            })()}
            iframe={{title: '《隐私政策》'}}
            user={false}
          >《隐私政策》</NavLink>
        <br/>
      </label>
    </div>

    <div className="head-oper">
      {/* <div className="back" onClick={() => general.history.goBack()}><LeftOutlined/></div> */}
      <Link onClick={() => general.history.goBack()} className="qa-back">
        <LeftOutlined/>
      </Link>
      <Link to="/qa" className="qa-text">
        常见问题？
      </Link>
    </div>
  </div>;
};