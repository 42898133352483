import {useState, useEffect} from "react";
import general from "../../general";
import {useMount, useToggle, useLocalStorageState, useLockFn, useInterval} from "ahooks";
import {CloseOutlined, DownOutlined, EyeInvisibleOutlined, EyeOutlined} from "@ant-design/icons";
import {prod, url} from "../../env";
import {Link, useHistory} from "react-router-dom";
import useActiveState from "../../lib/useActiveState";
import Dialog from "../../lib/Dialog";
import URI from "urijs";
import MobileDetect from "mobile-detect";
import {NavLink} from "../../lib/Navigation";
import useUrlState from "@ahooksjs/use-url-state";

const mobileDetect = new MobileDetect(navigator.userAgent);
export default props => {

  return <div className="qa-index">
    <div className="qa-body">
      <div className="items">
        <div className="item">
          <div className="title">
            <div className="icon">
              <img src="/resources/index/w.png" />
            </div>
            <div className="cont">
              为什么有很多游戏只有云玩没有下载?
            </div>
          </div>
          <div className="text">
            答：没有下载是因为游戏厂商只提供了安卓APP，iOSAPP游戏厂商没开发或已经停运了
          </div>
        </div>

        <div className="item">
          <div className="title">
            <div className="icon">
              <img src="/resources/index/w.png" />
            </div>
            <div className="cont">
              为什么云玩画质不太好?
            </div>
          </div>
          <div className="text">
            答：游戏实际在云端服务器上运行，与看视频一样，画面会渲染压缩后再传送到我们手机上，所以在画质上相较于下载游戏的原画会有损失
          </div>
        </div>

        <div className="item">
          <div className="title">
            <div className="icon">
              <img src="/resources/index/w.png" />
            </div>
            <div className="cont">
            为什么感觉云玩有些卡?
            </div>
          </div>
          <div className="text">
            答：云玩的流畅性和我们看视频一样，对网络的要求比较高，且流量消耗较多，当觉得比较卡时可以尝试切换网络或者降低清晰度哦~
          </div>
        </div>

        <div className="item">
          <div className="title">
            <div className="icon">
              <img src="/resources/index/w.png" />
            </div>
            <div className="cont">
            为什么要提供云玩?
            </div>
          </div>
          <div className="text">
            答：受ios技术封锁，较多游戏并未提供iOS下载包。还会受ios掉签影响，而云玩不受掉签影响，且可以让iOS玩家体验到更多的精品游戏~
          </div>
        </div>

        <div className="item">
          <div className="title">
            <div className="icon">
              <img src="/resources/index/w.png" />
            </div>
            <div className="cont">
              为什么有的游戏我用iOS下载包玩了后，再用云玩玩角色消失了?
            </div>
          </div>
          <div className="text">
            答：云玩所产生的数据信息属于安卓端，部分游戏安卓与ios端数据不互通，我们会在云玩进入前提前告知，请留意查看~
          </div>
        </div>

      </div>
    </div>
  </div>;
};