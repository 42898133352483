import {useState, useEffect} from "react";
import general from "../../general";
import {useMount, useToggle, useLocalStorageState, useLockFn, useInterval} from "ahooks";
import {CloseOutlined, DownOutlined, EyeInvisibleOutlined, EyeOutlined} from "@ant-design/icons";
import {prod, url} from "../../env";
import {Link, useHistory} from "react-router-dom";
import useActiveState from "../../lib/useActiveState";
import Dialog from "../../lib/Dialog";
import URI from "urijs";
import MobileDetect from "mobile-detect";
import {NavLink} from "../../lib/Navigation";

const mobileDetect = new MobileDetect(navigator.userAgent);
export default props => {
  useEffect(() => {
      props.setOperate(<Link to="/login" className="rebate-index-guide">密码登录</Link>);
  }, []);

  const history = useHistory();
  const {aplus_queue} = window;
  const [app] = useActiveState('app');
  useMount(() => {
    general.callback = props.query.callback || null;
  });
  const [datas, setDatas] = useLocalStorageState('users-0804', []);
  const [focus, setFocus] = useState('');
  const [data, setData] = useState(datas.length > 0 ? {...datas[0]} : {});
  // const [agreement, setAgreement] = useLocalStorageState('agreement', false);
  const [agreement, setAgreement] = useState(false);
  const [loging, setLoging] = useState(false);
  const [user, setUser] = useActiveState('user');

  const [codIng, setCodIng] = useState(false);
  const [codeTime, setCodeTime] = useState(0);
  const [users, setUsers] = useState([]);//多个账号
  const [agent] = useActiveState('agent');
  const [areaNumData, setAreaNumData] = useState([]);


  useEffect(() => {
    setAreaNumData(general.areaNumber);
  }, [props.active]);

  useInterval(() => {
    if (codeTime) {
      setCodeTime(codeTime - 1);
    }
  }, 1000);
  const codeGet = () => {
    if (!data.phone) {
        Dialog.error('请输入手机号');
        return;
    }
    if (codIng || codeTime) {
      return;
    }
    // if(agent == 'rr2f') {
    //   Dialog.info("当前渠道有误，请重新打开链接");
    //   return;
    // }
    setCodIng(true);
    Dialog.loading();
    general.axios.post('/base/tool/getVerifyCode', {
      mobile: (areaNumData?areaNumData:'86') + '-' + data.phone,
      code: 'code',
      type: '08',
    })
    .finally(() => {
      setCodIng(false);
      Dialog.close();
    })
    .then(res => {
      let {status} = res.data;
      if (!status.succeed) {
        Dialog.error(status.error_desc);
      } else {
        setCodeTime(60);
      }
    })
    .catch(err => {
      Dialog.error(err.message);
    });
  };

  const login = () => {
    if (!data.phone) {
      Dialog.error('请输入手机号');
      return;
    }
    if (!data.code) {
      Dialog.error('请输入验证码');
      return;
    }
    if (!agreement) {
      // Dialog.error('请阅读下方的协议');
      Dialog.info('请先阅读并同意《用户协议》和《隐私政策》');
      return;
    }
    if (loging) {
      return;
    }

    Dialog.loading();
    general.axios.post('/smsLogin', {
      mobile: data.phone,
      code: data.code,
    })
        .finally(() => {
          Dialog.close();
        })
        .then(res => {
          let {status, data} = res.data;
          console.log(data);
          if (!status.succeed) {
            Dialog.error(status.error_desc);
          } else {
            let users = data.users;
            if (users.length < 2) {
              if(!data.isSetPassword) {
                localStorage.setItem('token', data.token);
                localStorage.setItem('user_id', data.user_id);
                localStorage.setItem('username', data.username);
                aplus_queue.push({
                  action: 'aplus.record',
                  arguments: ['MobileNumberRegisterSuccess', 'CLK', {username: data.username, agent: agent}]
                });
                aplus_queue.push({
                  action: 'aplus.record',
                  arguments: ['register', 'CLK', {username: data.username, agent: agent}]
                });
                history.push('/user/setpassword');
              } else {
                localStorage.setItem('token', data.token);
                localStorage.setItem('user_id', data.user_id);
                localStorage.setItem('username', data.username);
                aplus_queue.push({
                  action: 'aplus.record',
                  arguments: ['MobileNumberLoginSuccess', 'CLK', {username: data.username, agent: agent}]
                });
                aplus_queue.push({
                  action: 'aplus.record',
                  arguments: ['login', 'CLK', {username: data.username, agent: agent}]
                });
                Dialog.success('登录成功', () => general.goBack(true));
              }
            } else {
              setUsers(users);
            }
          }
        })
        .catch(err => {
          Dialog.error(err.message);
        });
  }

  const finalLogin = user => {
    if (loging) {
      return;
    }
    setLoging(true);
    Dialog.loading();
    general.axios.post('/tickLogin', {username: user.username, tick: user.tick})
        .finally(() => {
          setLoging(false);
          Dialog.close();
        })
        .then(res => {
          let {status, data} = res.data;
          if (!status.succeed) {
            setLoging(false);
            Dialog.error(status.error_desc);
            return;
          }
          localStorage.setItem('token', data.token);
          localStorage.setItem('user_id', data.user_id);
          localStorage.setItem('username', data.username);
          aplus_queue.push({
            action: 'aplus.record',
            arguments: ['MobileNumberLoginSuccess', 'CLK', {
              username: data.username,
              agent: agent
            }]
          });
          aplus_queue.push({
            action: 'aplus.record',
            arguments: ['login', 'CLK', {username: data.username, agent: agent}]
          });
          // load_load();
          Dialog.success('登录成功', () => general.goBack(true));
        })
        .catch(err => {
          Dialog.error(err.message);
        });
  }

  // const load_load = useLockFn(async () => {
  //   await general.axios.post('/user/user/getMemberInfo')
  //     .finally(() => {
  //     })
  //     .then(response => {
  //       let {status, data} = response.data;
  //       if (!status.succeed) {

  //       } else {
  //         setUser({isLogin: true, ...data.member_info});
  //       }
  //     })
  //     .catch(err => {
  //         Dialog.error(err.message);
  //       });
  // })

  

  const updateAgreement = () => {
    let a = agreement == true ? false : (agreement == false ? true : false);
    setAgreement(a);
  }

  return <div className="account-verificode-login">
    <div className="verificode-body">
      {/* <div className="logo"><img src={app.applogo} alt=""/></div> */}
      <div className="logo"><img src='/resources/user/login_logo.png' alt=""/></div>
      <div className="input-item">
        <div className="label">
          {/* 手机号 */}
          <div className="area-num-text">
            <img src="/resources/account/jia.png" />
            <Link to="/account/country" className="value">
              {areaNumData ? areaNumData : '86'}
            </Link>
            <img src="/resources/account/sel-down.png" />
          </div>
        </div>
        <div className="input">
          <input name="phone" type="number" value={data.phone} placeholder="请输入手机号"
                 autoComplete="off"
                 onFocus={e => setFocus('phone')}
                 onChange={e => setData({...data, phone: e.target.value})}
          />
          {focus == 'phone' && data.phone
              ? <CloseOutlined
                  className="assist close"
                  onClick={() => setData({...data, phone: ''})}
              />
              : null}
        </div>
      </div>
      <div className="input-item">
        <div className="label">验证码</div>
        <div className="input">
          <input name="code" type={'number'} value={data.code} placeholder="请输入验证码"
                 onFocus={e => setFocus('code')}
                 onChange={e => setData({...data, code: e.target.value})}
          />
          {focus == 'code' && data.code
              ? <CloseOutlined
                  className="assist close"
                  onClick={() => setData(data => ({...data, code: ''}))}/>
              : null}
        </div>
        <div className={'code-btn ' + (codIng || codeTime ? 'disable' : '')} onClick={codeGet}>
            {codIng ? '发送中' : (codeTime ? (codeTime + '秒后重发') : '发送验证码')}
          </div>
      </div>
      <p>未注册的用户可直接注册登录</p>
      <div className={'operate ' + (loging ? 'disable' : '')} onClick={login}>登录</div>
    </div>
    {users.length > 0 ? <div className="verificodelogin-users">
      <div className="mask" onClick={() => setUsers([])}/>
      <div className="body">
        <div className="title">选择用户</div>
        <div className="users">
          {users.map(user => <div className="user" onClick={() => finalLogin(user)}>{user.username}</div>)}
        </div>
      </div>
    </div> : null}
    <div className="account-agreement">
      <label>
        <span className={'agreement' + (agreement ? ' checked' : '')}  onClick={updateAgreement}/>
        登录即代表您同意
        <NavLink to={(() => {
            // let url = URI(url + '/userAgreement')
            let url = URI('//api.gamebox.i52wan.com/userAgreement')
                .addQuery('title', '用户协议')
                .addQuery('channel', agent)
                .addQuery('device_type', (mobileDetect.os() == 'iOS'?10:20))
                .toString();
              return url;
            })()}
            iframe={{title: '《用户协议》'}}
            user={false}
          >《用户协议》</NavLink>
        <NavLink to={(() => {
            // let url = URI(url + '/userAgreement')
            let url = URI('//api.gamebox.i52wan.com/privacyPolicy')
                .addQuery('title', '隐私政策')
                .addQuery('channel', agent)
                .addQuery('device_type', (mobileDetect.os() == 'iOS'?10:20))
                .toString();
              return url;
            })()}
            iframe={{title: '《隐私政策》'}}
            user={false}
          >《隐私政策》</NavLink>
        {/* <Link to={'/iframe?url=' + url + '/userAgreement?title=用户协议&channel=ue70&device_type=' + (mobileDetect.os() == 'iOS'?10:20)}>《用户协议》</Link>、 */}
        {/* <Link to={'/iframe?url=' + url + '/privacyPolicy?title=隐私政策&channel=ue70&device_type=' + (mobileDetect.os() == 'iOS'?10:20)}>《隐私政策》</Link> */}
        <br/>
        接受免除或者限制责任、诉讼管辖约定等粗体标示条款
      </label>
    </div>
  </div>;
};