import {useState, useEffect, useCallback} from "react";
import general from "../../general";
import {useMount, useToggle, useLocalStorageState, useLockFn} from "ahooks";
import {CloseOutlined, DownOutlined, EyeInvisibleOutlined, EyeOutlined} from "@ant-design/icons";
import {prod, url} from "../../env";
import {Link, useHistory} from "react-router-dom";
import useActiveState from "../../lib/useActiveState";
import Dialog from "../../lib/Dialog";
import URI from "urijs";
import VipDownload from "./VipDownload";
import MobileDetect from "mobile-detect";
import {NavLink} from "../../lib/Navigation";
import useUrlState from "@ahooksjs/use-url-state";
import moment from "moment";

const mobileDetect = new MobileDetect(navigator.userAgent);
export default props => {
  const [params, setParams] = useUrlState({}, {navigateMode: 'replace'});
  const history = useHistory();
  const {aplus_queue} = window;
  const [app] = useActiveState('app');

  const [datas, setDatas] = useLocalStorageState('users-0804', !prod ? [
    {username: 'ceshi2', password: 'maiyou123tyc'},
    {username: 'yang004', password: '123456'},
  ] : []);
  const [focus, setFocus] = useState('');
  const [password, {toggle: togglePassword}] = useToggle(true);
  const [data, setData] = useState(datas.length > 0 ? {...datas[0]} : {});
  // const [agreement, setAgreement] = useLocalStorageState('agreement', false);
  const [agreement, setAgreement] = useState(true);
  const [loging, setLoging] = useState(false);
  const [user, setUser] = useActiveState('user');
  const [agent] = useActiveState('agent');
  const [udid] = useActiveState('udid');

  const [gameId, setGameid] = useActiveState('gameId');
  const [username, setUsername] = useActiveState('username');
  const [token, setToken] = useActiveState('token');
  const [gameInfo] = useActiveState('gameInfo');
  const [vipDownload, setVipDownload] = useState(false);
  const [interceptState, setInterceptState] = useState(false); //是否弹那个卡设备的框

  // console.log(localStorage.getItem('username'));
  const login = () => {
    if(localStorage.getItem('username') == '') {
      Dialog.info('请先登录');
      history.push('/auth?gameid=' + gameInfo.maiyou_gameid);
      return;
    }

    if(localStorage.getItem('username') == 'undefined') {
      Dialog.info('请先登录');
      history.push('/auth?gameid=' + gameInfo.maiyou_gameid);
      return;
    }
    Dialog.loading();

    general.axios.post(gameInfo.cloudappCheckAvailableUrl + "?gameid=" + gameId + "&username=" + localStorage.getItem('username'))
      .finally(() => {
        Dialog.close();
      })
      .then(res => {
        let {status, data} = res.data;
        if (!status.succeed) {
          Dialog.info(status.error_desc);
        } else {
          if(data.isValid == '1') {
            let hrefUrl = URI('/iframenogen')
              .addQuery('url', URI(gameInfo.cloudappIndexUrl)
              .addQuery('gameid', gameId)
              .addQuery('username', localStorage.getItem('username'))
              .addQuery('token', localStorage.getItem('token'))
              .addQuery('gameicon', gameInfo.game_image?.thumb)
              .addQuery('gamename', gameInfo.game_name)
              .addQuery('nameremark', gameInfo.nameRemark)
              .addQuery('location_url', encodeURIComponent(window.location.origin))
              .addQuery('title', "aaaaa")
              .addQuery('gamefullscreen', 1)
            )
            .toString();
            history.push(hrefUrl)
          } else {
            Dialog.info('暂没有可用的云设备');
          }
        }
      })
      .catch(err => {
        Dialog.error(err.message);
      });
  }

  const updateAgreement = () => {
    let a = agreement == true ? false : (agreement == false ? true : false);
    setAgreement(a);
  }

  const switchAccount = () => {
    history.push('/auth?gameid=' + gameInfo.maiyou_gameid);
  }
  
  const [startState, setStartState] = useLocalStorageState('startState', true);
  const [isIndexNoticeDatePop, setIsIndexNoticeDatePop] = useLocalStorageState('index-notice', []); //状态
  const [isFirstAccess, setIsFirstAccess] = useActiveState('isFirstAccess');
  const [equipData, setEquipData] = useState();

  useEffect(()=>{
    let asIs = gameId ?? localStorage.getItem('gameId');
    let today = asIs + moment().format('YYYY-MM-DD');

    // console.log('jinlaile');
    // console.log(startState);
    //进入云游   gamefullscreen:'1'
    if(gameId && username && token && startState && window.navigator.standalone && isIndexNoticeDatePop != 1) {
      setIsIndexNoticeDatePop(1);
      // window.location.href="https://sdk2.wakaifu.com/ysy?gameid=" + gameId + "&username=" + userName + "&token=" + token + "&userid=11692250&origin=react&location_url=" + encodeURIComponent(window.location.origin);
      let hrefUrl = URI('/iframenogen')
        .addQuery('url', URI(gameInfo.cloudappIndexUrl)
        .addQuery('gameid', gameInfo.maiyou_gameid)
        // .addQuery('userid', localStorage.getItem('user_id'))
        .addQuery('username', localStorage.getItem('username'))
        .addQuery('token', token)
        .addQuery('gamefullscreen', 1)
        .addQuery('gameicon', gameInfo.game_image?.thumb)
        .addQuery('gamename', gameInfo.game_name)
        .addQuery('nameremark', gameInfo.nameRemark)
        .addQuery('location_url', encodeURIComponent(window.location.origin))
        )
        .toString();

      history.push(hrefUrl)
    }
    console.log('quit');
  }, [gameId, username, token, startState]);

  //这是监听云游信息
  useEffect(()=>{
    window.addEventListener('message', function(event) {
      var result = event?.data;
      if(result) {
        if(result?.type) {
          if(result.type == 'pay') {
            window.location.href = result.pay_url;
          } else if(result.type == 'exitCloudApp') {
            // console.log(result);
            console.log("退出了~清东西了");
            setStartState(false);
            history.push('/?gameid=' + gameInfo.maiyou_gameid)
            // setUserName(userName = '');
            // setToken(token = '');
            // localStorage.setItem('username', '');
            // localStorage.setItem('token', '');
          } else if(result.type == 'kefu') {
            window.location.href = window.location.origin + "/#/service?gameid=" + gameInfo.maiyou_gameid;
          }
        } else {
          if(result?.pay_url) {
            window.location.href = result.pay_url;
          }
        }
      }
    });
  });

  const [freeState, setFreeState] = useState(false); //是否免费下载
  const [textState, setTextState] = useState(false); //文字状态
  const [notUdidState, setNotUdidState] = useState(false); //没有udid文字控制
  useEffect(()=>{
    if(window.navigator.standalone) {
      general.axios.post("/base/game/cardEquipment")
        .finally(() => {
        })
        .then(res => {
          let {status, data} = res.data;
          if (!status.succeed) {
            Dialog.info(status.error_desc);
          } else {
            setEquipData(data);

            if(gameInfo.device_type != '20') {
              if(data.udid_info) {
                if(data.udid_info.status == '-1') {

                } else {
                  setInterceptState(true);
                  setFreeState(true);
                  setTextState(true);//至尊版文字
                  setNotUdidState(false);
                }
              } else {
                // if(udid && udid != 'null') {
                //   if(data.amount > 100) {
                //     setInterceptState(true);
                //     setFreeState(true);
                //     setTextState(false);//没有udid
                //     setNotUdidState(false);
                //   } else {
                //   }
                // } else {
                //   setInterceptState(true);
                //   setFreeState(false);
                //   setTextState(false);//至尊版文字
                //   setNotUdidState(true);
                // }

                if(data.amount > 100) {
                  if(udid && udid != 'null') {
                    setInterceptState(true);
                    setFreeState(true);
                    setTextState(false);//没有udid
                    setNotUdidState(false);
                  } else {
                    setInterceptState(true);
                    setFreeState(false);
                    setTextState(false);//至尊版文字
                    setNotUdidState(true);
                  }
                } else {
                  setInterceptState(true);
                  setFreeState(false);
                  setTextState(false);//至尊版文字
                  setNotUdidState(true);
                }

              }

            }

          }
        })
        .catch(err => {
          Dialog.error(err.message);
        });
    }
  }, [udid])

  //下载
  const download = () => {
    if(freeState) {
      setInterceptState(false);
      setVipDownload(true);
    } else {
      let url = "https://webbox.99maiyou.cn/#/game?id=" + gameInfo.game_id + "&agent=" + agent + "&username=" + localStorage.getItem('username') + "&token="+localStorage.getItem('token') + "&userid="+localStorage.getItem('user_id');
      if(app?.platformName == 'milu') {
        url = "https://webbox.99maiyou.cn/#/game?id=" + gameInfo.game_id + "&agent=" + agent + "&username=" + localStorage.getItem('username') + "&token="+localStorage.getItem('token') + "&userid="+localStorage.getItem('user_id');
      } else if(app?.platformName == '52wan') {
        url = "https://webbox.i52wan.com/#/game?id=" + gameInfo.game_id + "&agent=" + agent + "&username=" + localStorage.getItem('username') + "&token="+localStorage.getItem('token') + "&userid="+localStorage.getItem('user_id');
      } else if(app?.platformName == 'heigu') {
        url = "https://webbox.heigu.com/#/game?id=" + gameInfo.game_id + "&agent=" + agent + "&username=" + localStorage.getItem('username') + "&token="+localStorage.getItem('token') + "&userid="+localStorage.getItem('user_id');
      } else if(app?.platformName == 'huizhua') {
        url = "https://webbox.ihuizhua.com/#/game?id=" + gameInfo.game_id + "&agent=" + agent + "&username=" + localStorage.getItem('username') + "&token="+localStorage.getItem('token') + "&userid="+localStorage.getItem('user_id');
      }
  
      setInterceptState(false);
      window.open(url, "_blank");
    }
  }

  // console.log(gameInfo);
  return <div className="account-auth">
    <div className="body">
      <div className="logo">
        <img src={app.applogo} alt=""/>
      </div>
      <div className="text">
        <div className="name">
          {app.appname}
        </div>
        <div className="nameRemark">
          <span>
            {gameInfo?.nameRemark}
          </span>
        </div>
      </div>
      <div className="input-item">
        <div className="input">
          <img src="/resources/index/bz.png" className="auth-img-tips"/>
          <input name="username" type="text" value={localStorage.getItem('username') && localStorage.getItem('username') != 'undefined' ? localStorage.getItem('username') + '(上次登录)':''} disabled placeholder="立即登录 畅玩游戏"
                 autoComplete="off"
          />
          <div className={"switch" + (app?.platformName != 'milu' ? (app?.platformName == 'huizhua' ? ' huizhua ' : ' barter') : ' ')} onClick={()=>{switchAccount()}}>
            切换账号
          </div>
        </div>
      </div>
      <div className={'btn ' + (loging ? 'disable' : '') + (app?.platformName != 'milu' ? (app?.platformName == 'huizhua' ? ' huizhua ' : ' barter') : ' ')} onClick={()=>login()}>登录</div>
    </div>
    <div className={"account-agreement" + (app?.platformName != 'milu' ? (app?.platformName == 'huizhua' ? ' huizhua ' : ' barter') : ' ')}>
    <label>
        <span className={'agreement' + (agreement ? ' checked' : '')}  onClick={updateAgreement}/>
        登录注册代表您同意
        <NavLink to={(() => {
            let url = URI('//api.gamebox.i52wan.com/userAgreement')
                .addQuery('title', '用户协议')
                .addQuery('channel', agent)
                .addQuery('device_type', (mobileDetect.os() == 'iOS'?10:20))
                .toString();
              return url;
            })()}
            iframe={{title: '《用户协议》'}}
            user={false}
          >《用户协议》</NavLink>
        <NavLink to={(() => {
            let url = URI('//api.gamebox.i52wan.com/privacyPolicy')
                .addQuery('title', '隐私政策')
                .addQuery('channel', agent)
                .addQuery('device_type', (mobileDetect.os() == 'iOS'?10:20))
                .toString();
              return url;
            })()}
            iframe={{title: '《隐私政策》'}}
            user={false}
          >《隐私政策》</NavLink>
        <br/>
      </label>
    </div>

    <Link to="/qa" className="qa-text">
      常见问题？
    </Link>

    {vipDownload ? <VipDownload game={gameInfo} setVipDownload={setVipDownload} /> : null}

    {interceptState ?
    <div className="game-udidxz-index">
    <div className="exit-site">
      <div className="exit-main">
        <div className="exit-title">
        温馨提示
        </div>
        <div className="info">
          {(()=>{
            if(textState) {
              return <p>您当前设备UDID已通过苹果官方审核，为了更好的游戏体验，请点击“免费下载”按钮安装此游戏使用，如需继续使用云游戏请点击“跳过”按钮”</p>
            } else {
              if(notUdidState) {
                return <p>
                  为了更好的游戏体验，请下载安装此游戏使用，如需继续使用云游戏请点击“跳过”按钮
                </p>
              } else {
                return <p>
                  恭喜您已获得至尊版下载权限，为了更好的游戏体验，请点击“免费下载”按钮安装此游戏使用，如需继续使用云游戏请点击“跳过”按钮
                </p>
              }
            }
          })()}
        </div>
        <div className="exit-btn">
          <div className="item btn-about" onClick={()=>setInterceptState(false)}>
            跳过
          </div>

          <div className={"item btn-confirm " + (app?.platformName == 'milu' ? '' : app?.platformName == 'huizhua' ? ' color-huizhua-confirm ' : ' color-confirm') } onClick={()=>{download()}}>
            {(()=>{
            if(textState) {
              return "免费下载(推荐)";
            } else {
              if(notUdidState) {
                return "下载(推荐)";
              } else {
                return "免费下载(推荐)";
              }
            }
          })()}
          </div>
        </div>
      </div>
    </div>
    </div>
    : null }

  </div>;
};