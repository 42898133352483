import Index from "./view/index/Index";
import Img from "./view/general/Img";
import Iframe from "./view/general/Iframe";
import Forget from "./view/account/Forget";
// import Register from "./view/account/Register";
import Login from "./view/account/Login";
import VerifiCode from "./view/account/VerifiCode";
// import Phone from "./view/account/Phone";
import Country from "./view/account/Country";
import Phone  from "./view/index/Phone";
import Qa from "./view/index/Qa";
import Iframenogen from "./view/general/Iframenogen";
import Auth from "./view/index/Auth";
import Register from "./view/index/Register";
import Service from "./view/Service";

export const screenRouter = [
  {
    id: 'index',
    path: '/',
    component: Index,
    title: '首页',
  },
  {
    id: 'phone',
    path: '/phone',
    component: Phone,
    title: '验证码登录',
  },
  {
    id: 'auth',
    path: '/auth',
    component: Auth,
    title: '自动登录',
  },
  {
    id: 'register',
    path: '/register',
    component: Register,
    title: '账号注册',
  },
  // {
  //   id:'iframe',
  //   path: '/iframe',
  //   component: Iframe,
  //   title: '云玩',
  // },
  // {
  //   id: 'game',
  //   path: '/screen-game',
  //   component: Game,
  //   title: '分类',
  // },
  // {
  //   id: 'welfare',
  //   path: '/screen-welfare',
  //   component: Welfare,
  //   title: '福利中心',
  //   user: true,
  // },
  // {
  //   id: 'task',
  //   path: '/screen-task',
  //   component: Task,
  //   title: '赚金',
  //   user: true,
  // },
  // {
  //   id: 'trade',
  //   path: '/screen-trade',
  //   component: TradeIndex,
  //   title: '账号交易',
  //   user: false,
  // },
  // {
  //   id: 'service',
  //   path: '/screen-service',
  //   component: Service,
  //   title: '客服',
  // },
  // {
  //   id: 'user',
  //   path: '/screen-user',
  //   component: User,
  //   title: '我的',
  // },

];

export const navigationRouter = [
  {
    path: '/img',
    component: Img,
    invasion: true,
    theme: 'bright',
  },
  // {
  //   path: '/phone',
  //   component: Phone,
  //   title: '',
  //   invasion: true,
  //   theme: 'darkreagein',
  // },

  {
    path: '/iframe',
    component: Iframe,
  },
  {
    path: '/iframenogen',
    component: Iframenogen,
    gamefullscreen: 1,
  },
  {
    path: '/login',
    component: Login,
    title: '账号密码登录',
    invasion: true,
    // theme: 'darklogin',
    theme: 'darkreagein',
  },
  // {
  //   path: '/register',
  //   component: Register,
  //   title: '账号注册',
  //   invasion: true,
  //   // theme: 'darklogin',
  //   theme: 'darkreagein',
  // },
  {
    path: '/verificode',
    component: VerifiCode,
    title: '验证码登录',
    invasion: true,
    // theme: 'darklogin',
    theme: 'darkreagein',
    // color: '255,190,0',
    // background: '#fff',
  },
  // {
  //   path: '/phone',
  //   component: Phone,
  //   title: '绑定手机',
  // },
  {
    path: '/forget',
    component: Forget,
    title: '忘记密码',
  },
  {
    path: '/account/country',
    component: Country,
    title: '选择国家或地区',
  },
  {
    path: '/qa',
    component: Qa,
    title: '常见问题',
  },
  {
    path: '/service',
    component: Service,
    title: '客服中心',
  },
];