import {SearchOutlined} from '@ant-design/icons';
import {useEffect, useRef, useState, useCallback} from "react";
import Good from "./Good";
import URI from "urijs";
import {Link, useHistory, useLocation} from "react-router-dom";
import {useLocalStorageState, useClickAway, useInterval} from "ahooks";
import moment from "moment";
import general from "../../general";
import $ from "jquery";

export default props => {
  // const [operate, setOperate] = useState(null);
  return <div>
    <div className={'index-index '}>
    <div className="index-head width">
    </div>
    <div className="index-body">
      <Good active={props.active}/>
    </div>
  </div>
  </div>;
};