import {useState, useEffect} from "react";
import general from "../../general";
import {useMount, useToggle, useLocalStorageState, useLockFn, useInterval} from "ahooks";
import {CloseOutlined, DownOutlined, EyeInvisibleOutlined, EyeOutlined} from "@ant-design/icons";
import {prod, url} from "../../env";
import {Link, useHistory} from "react-router-dom";
import useActiveState from "../../lib/useActiveState";
import Dialog from "../../lib/Dialog";
import URI from "urijs";
import MobileDetect from "mobile-detect";
import {NavLink} from "../../lib/Navigation";
import useUrlState from "@ahooksjs/use-url-state";
import moment from "moment";
import {LeftOutlined} from "@ant-design/icons";

const mobileDetect = new MobileDetect(navigator.userAgent);
export default props => {
  const [params, setParams] = useUrlState({}, {navigateMode: 'replace'});
  const history = useHistory();
  const {aplus_queue} = window;
  const [app] = useActiveState('app');
  const [datas, setDatas] = useLocalStorageState('users-0804', []);
  const [focus, setFocus] = useState('');
  const [data, setData] = useState(datas.length > 0 ? {...datas[0]} : {});
  const [agreement, setAgreement] = useState(true);
  const [loging, setLoging] = useState(false);
  const [user, setUser] = useActiveState('user');
  const [codIng, setCodIng] = useState(false);
  const [codeTime, setCodeTime] = useState(0);
  const [users, setUsers] = useState([]);//多个账号
  const [agent] = useActiveState('agent');
  const [areaNumData, setAreaNumData] = useState([]);
  const [passState, setPassState] = useState(false);
  const [gameId] = useActiveState('gameId');
  const [gameInfo] = useActiveState('gameInfo');

  const [username, setUsername] = useActiveState('username');
  const [token, setToken] = useActiveState('token');

  useEffect(() => {
    setAreaNumData(general.areaNumber);
  }, [props.active]);

  useInterval(() => {
    if (codeTime) {
      setCodeTime(codeTime - 1);
    }
  }, 1000);
  const codeGet = () => {
    if (!data.phone) {
        Dialog.error('请输入手机号');
        return;
    }
    if (codIng || codeTime) {
      return;
    }

    setCodIng(true);
    Dialog.loading();
    general.axios.post('/base/tool/getVerifyCode', {
      mobile: (areaNumData?areaNumData:'86') + '-' + data.phone,
      code: 'code',
      type: '08',
    })
    .finally(() => {
      setCodIng(false);
      Dialog.close();
    })
    .then(res => {
      let {status} = res.data;
      if (!status.succeed) {
        Dialog.error(status.error_desc);
      } else {
        setCodeTime(60);
      }
    })
    .catch(err => {
      Dialog.error(err.message);
    });
  };

  const login = () => {
    if (!data.phone) {
      Dialog.error('请输入手机号');
      return;
    }
    if (!data.code) {
      Dialog.error('请输入验证码');
      return;
    }
    if (!agreement) {
      Dialog.info('请先阅读并同意《用户协议》和《隐私政策》');
      return;
    }
    if (loging) {
      return;
    }

    Dialog.loading();
    general.axios.post('/smsLogin', {
      mobile: data.phone,
      code: data.code,
    })
        .finally(() => {
          Dialog.close();
        })
        .then(res => {
          let {status, data} = res.data;
          console.log(data);
          if (!status.succeed) {
            Dialog.error(status.error_desc);
          } else {
            let users = data.users;
            if (users.length < 2) {
              if(!data.isSetPassword) {
                localStorage.setItem('token', data.token);
                localStorage.setItem('user_id', data.user_id);
                localStorage.setItem('username', data.username);

                // history.push('/user/setpassword');
                //设置密码

                setPassState(true);
              } else {
                localStorage.setItem('token', data.token);
                localStorage.setItem('user_id', data.user_id);
                localStorage.setItem('username', data.username);
                // Dialog.success('登录成功', () => general.goBack(true));

                let hrefUrl = URI('/iframenogen')
                  .addQuery('url', URI(gameInfo.cloudappIndexUrl)
                  .addQuery('gameid', gameId)
                  // .addQuery('userid', localStorage.getItem('user_id'))
                  .addQuery('username', localStorage.getItem('username'))
                  .addQuery('token', localStorage.getItem('token'))
                  .addQuery('gameicon', gameInfo.game_image?.thumb)
                  .addQuery('gamename', gameInfo.game_name)
                  .addQuery('nameremark', gameInfo.nameRemark)
                  .addQuery('gamefullscreen', 1)
                  .addQuery('location_url', encodeURIComponent(window.location.origin))
                )
                .toString();

                history.push(hrefUrl)
                // window.location.href = hrefUrl;
              }
            } else {
              setUsers(users);
            }
          }
        })
        .catch(err => {
          Dialog.error(err.message);
        });
  }

  const finalLogin = user => {
    if (loging) {
      return;
    }
    setLoging(true);
    Dialog.loading();
    general.axios.post('/tickLogin', {username: user.username, tick: user.tick})
        .finally(() => {
          setLoging(false);
          Dialog.close();
        })
        .then(res => {
          let {status, data} = res.data;
          if (!status.succeed) {
            setLoging(false);
            Dialog.error(status.error_desc);
            return;
          }

          localStorage.setItem('token', data.token);
          // localStorage.setItem('user_id', data.user_id);
          localStorage.setItem('username', data.username);
     
          // load_load();
          // Dialog.success('登录成功', () => general.goBack(true));
          let hrefUrl = URI('/iframenogen')
              .addQuery('url', URI(gameInfo.cloudappIndexUrl)
              .addQuery('gameid', gameId)
              // .addQuery('userid', localStorage.getItem('user_id'))
              .addQuery('username', localStorage.getItem('username'))
              .addQuery('token', localStorage.getItem('token'))
              .addQuery('gameicon', gameInfo.game_image?.thumb)
              .addQuery('gamename', gameInfo.game_name)
              .addQuery('nameremark', gameInfo.nameRemark)
              .addQuery('gamefullscreen', 1)
              .addQuery('location_url', encodeURIComponent(window.location.origin))
            )
            .toString();

            history.push(hrefUrl)
            // window.location.href = hrefUrl;
        })
        .catch(err => {
          Dialog.error(err.message);
        });
  }

  const updateAgreement = () => {
    let a = agreement == true ? false : (agreement == false ? true : false);
    setAgreement(a);
  }



  const [passWordData, setPasswordData] = useState({});
  // const [passWord, setPassword] = useState([]);

  const save = () => {
    if (!passWordData.password) {
      Dialog.error('请输入密码');
      return;
    }
    // setSaving(true);
    Dialog.loading();
    general.axios.post('/setPassword', {
      headers: {username: localStorage.getItem('username'), token: localStorage.getItem('token')},
      member_id: localStorage.getItem('user_id'),
      password: passWordData.password,
    })
    .finally(() => {
      // setSaving(false);
      Dialog.close();
    })
    .then(response => {
        let {status, data} = response.data;
        if (!status.succeed) {
            Dialog.error(status.error_desc);
            return;
        }

        localStorage.setItem('token', data.token);
        // localStorage.setItem('user_id', data.user_id);
        localStorage.setItem('username', data.username);
        setPassState(false);
        let hrefUrl = URI('/iframenogen')
          .addQuery('url', URI(gameInfo.cloudappIndexUrl)
          .addQuery('gameid', gameId)
          // .addQuery('userid', localStorage.getItem('user_id'))
          .addQuery('username', localStorage.getItem('username'))
          .addQuery('token', localStorage.getItem('token'))
          .addQuery('gameicon', gameInfo.game_image?.thumb)
          .addQuery('gamename', gameInfo.game_name)
          .addQuery('nameremark', gameInfo.nameRemark)
          .addQuery('gamefullscreen', 1)
          .addQuery('location_url', encodeURIComponent(window.location.origin))
        )
        .toString();

        history.push(hrefUrl)
        // window.location.href = hrefUrl;
    })
    .catch(err => {
      Dialog.error(err.message);
    });
  };

  // const [startState, setStartState] = useLocalStorageState('startState', true);
  // const [isIndexNoticeDatePop, setIsIndexNoticeDatePop] = useLocalStorageState('index-notice', []); //状态

  // console.log(gameInfo);
  // useEffect(()=>{
  //   let asIs = gameId ?? localStorage.getItem('gameId');
  //   let today = asIs + moment().format('YYYY-MM-DD');

  //   console.log('jinlaile');
  //   // console.log(startState);
  //   //进入云游   gamefullscreen:'1'
  //   console.log(isIndexNoticeDatePop);
  //   if(gameId && userName && token && startState && window.navigator.standalone && isIndexNoticeDatePop != 1) {
  //     setIsIndexNoticeDatePop(1);
  //     // window.location.href="https://sdk2.wakaifu.com/ysy?gameid=" + gameId + "&username=" + userName + "&token=" + token + "&userid=11692250&origin=react&location_url=" + encodeURIComponent(window.location.origin);
  //     let hrefUrl = URI('/iframenogen')
  //       .addQuery('url', URI(gameInfo.cloudappIndexUrl)
  //       .addQuery('gameid', gameInfo.maiyou_gameid)
  //       // .addQuery('userid', localStorage.getItem('user_id'))
  //       .addQuery('username', userName)
  //       .addQuery('token', token)
  //       .addQuery('gamefullscreen', 1)
  //       .addQuery('location_url', encodeURIComponent(window.location.origin))
  //       )
  //       .toString();

  //     history.push(hrefUrl)
  //     // window.location.href = hrefUrl;
  //   }
  //   console.log('quit');
  // }, [gameId, userName, token, startState]);

  // //这是监听云游信息
  // useEffect(()=>{
  //   window.addEventListener('message', function(event) {
  //     var result = event.data;
  //     // if(result.type == 'exitCloudApp') {
  //     //   console.log(result);
  //     //   console.log("退出了~清东西了");
  //     //   setStartState(false);
  //     //   setUserName(userName = '');
  //     //   setToken(token = '');
  //     //   localStorage.setItem('username', '');
  //     //   localStorage.setItem('token', '');
  //     // }

  //     if(result?.type) {
  //       if(result.type == 'pay') {
  //         window.location.href = result.pay_url;
  //       } else if(result.type == 'exitCloudApp') {
  //         console.log(result);
  //         console.log("退出了~清东西了");
  //         setStartState(false);
  //         // setUserName(userName = '');
  //         // setToken(token = '');
  //         // localStorage.setItem('username', '');
  //         // localStorage.setItem('token', '');
  //       }
  //     } else {
  //       window.location.href = result.pay_url;
  //     }


  //   });
  // });

  return <div className="account-verificode-login">
    <div className="verificode-body">
      <div className="logo"><img src={app.applogo} alt=""/></div>

      <div className="text">
        <div className="name">
          {app.appname}
        </div>
        <div className="nameRemark">
          <span>
            {gameInfo?.nameRemark}
          </span>
        </div>
      </div>
      <div className="title">
        验证码登录
      </div>

      <div className="label-tips">
      *未注册手机号完成登录后自动注册
      </div>
      <div className="input-item">
        <div className="label">
          {/* 手机号 */}
          <div className="area-num-text">
            <img src="/resources/account/jia.png" />
            <Link to="/account/country" className="value">
              {areaNumData ? areaNumData : '86'}
            </Link>
            <img src="/resources/account/sel-down.png" />
          </div>
        </div>
        <div className="input">
          <input name="phone" type="number" value={data.phone} placeholder="请输入手机号"
                 autoComplete="off"
                 onFocus={e => setFocus('phone')}
                 onChange={e => setData({...data, phone: e.target.value})}
          />
          {focus == 'phone' && data.phone
              ? <CloseOutlined
                  className="assist close"
                  onClick={() => setData({...data, phone: ''})}
              />
              : null}
        </div>
      </div>
      <div className="input-item">
        <div className="input">
          <input name="code" type={'number'} value={data.code} placeholder="请输入验证码"
                 onFocus={e => setFocus('code')}
                 onChange={e => setData({...data, code: e.target.value})}
          />
          {focus == 'code' && data.code
              ? <CloseOutlined
                  className="assist close"
                  onClick={() => setData(data => ({...data, code: ''}))}/>
              : null}
        </div>
        <div className={'code-btn ' + (codIng || codeTime ? 'disable' : '') + ((app?.platformName != 'milu' ? (app?.platformName == 'huizhua' ? ' huizhua ' : ' barter') : ' '))} onClick={codeGet}>
            {codIng ? '发送中' : (codeTime ? (codeTime + '秒后重发') : '发送验证码')}
          </div>
      </div>
      <div className={"operway" + (app?.platformName != 'milu' ? (app?.platformName == 'huizhua' ? ' huizhua ' : ' barter') : ' ')}>
        <Link to={"/phone?gameid=" + gameInfo.maiyou_gameid} className="item">密码登录</Link>
        <Link to={"/register?gameid=" + gameInfo.maiyou_gameid} className="item" style={{paddingLeft:'.3rem'}}>快速注册</Link>
      </div>
      <div className={'operate ' + (loging ? 'disable' : '') + (app?.platformName != 'milu' ? (app?.platformName == 'huizhua' ? ' huizhua ' : ' barter') : ' ')} onClick={login}>登录</div>
    </div>
    {users.length > 0 ? <div className="verificodelogin-users">
      <div className="mask" onClick={() => setUsers([])}/>
      <div className="body">
        <div className="title">选择你要登录的账户</div>
        <div className="users">
          {users.map(user => <div className="user" onClick={() => finalLogin(user)}>{user.username}</div>)}
        </div>
      </div>
    </div> : null}

    {passState ? 
    <div className="verificodelogin-password">
      <div className="mask" />
      <div className="body">
        <div className="title">设置密码</div>
        <div className="input-item">
          <div className="input">
            <input name="code" type={'number'} value={passWordData.password} placeholder="请输入密码"
                  onFocus={() => setFocus('password')} onChange={e => setPasswordData({...passWordData, password: e.target.value})}
            />

            {focus === 'password' && passWordData.password ? <CloseOutlined className="close" onClick={() => setPasswordData({...passWordData, password: ''})}/> : null}
          </div>
        </div>
        <div className={'operate ' + (app?.platformName != 'milu' ? (app?.platformName == 'huizhua' ? ' huizhua ' : ' barter') : ' ')} onClick={save}>确定</div>
      </div>
    </div>
    : null}

    <div className={"account-agreement" + (app?.platformName != 'milu' ? (app?.platformName == 'huizhua' ? ' huizhua ' : ' barter') : ' ')}>
      <label>
        <span className={'agreement' + (agreement ? ' checked' : '')}  onClick={updateAgreement}/>
        登录注册代表您同意
        <NavLink to={(() => {
            let url = URI('//api.gamebox.i52wan.com/userAgreement')
                .addQuery('title', '用户协议')
                .addQuery('channel', agent)
                .addQuery('device_type', (mobileDetect.os() == 'iOS'?10:20))
                .toString();
              return url;
            })()}
            iframe={{title: '《用户协议》'}}
            user={false}
          >《用户协议》</NavLink>
        <NavLink to={(() => {
            let url = URI('//api.gamebox.i52wan.com/privacyPolicy')
                .addQuery('title', '隐私政策')
                .addQuery('channel', agent)
                .addQuery('device_type', (mobileDetect.os() == 'iOS'?10:20))
                .toString();
              return url;
            })()}
            iframe={{title: '《隐私政策》'}}
            user={false}
          >《隐私政策》</NavLink>
        <br/>
      </label>
    </div>

    {/* <Link to="/qa" className="qa-text">
      常见问题？
    </Link> */}

    <div className="head-oper">
      {/* <div className="back" onClick={() => general.history.goBack()}><LeftOutlined/></div> */}
      <Link to={'/?gameid=' + gameInfo.maiyou_gameid} className="qa-back">
        <LeftOutlined/>
      </Link>
      <Link to="/qa" className="qa-text">
        常见问题？
      </Link>
    </div>
  </div>;
};