import {useEffect, useState} from "react";
import general from "../general";
import {Link} from "react-router-dom";
import {useReactive} from "ahooks";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import Dialog from "../lib/Dialog";
import useActiveState from "../lib/useActiveState";

export default props => {
  const [init, setInit] = useState(false);
  const [custromState, setCustromState] = useState(false);
  let [app] = useActiveState('app');
  const data = useReactive({
    faqGroups: []
  });
  const load = () => {
    Dialog.loading();
    general.axios.post('/base/kefu/index')
      .finally(() => {
        Dialog.close();
      })
      .then(response => {
        setInit(true);
        let result = response.data.data;
        data.qq = result.kefu_info.qq;
        data.time = result.kefu_info.online_time;
        data.weixin = result.kefu_info.wechat_public_number_title;
        data.tel = result.complaint_tel;
        data.faqGroups = result.faq;

        data.is_private_kefu = result.kefu_info.is_private_kefu;
        data.kefu_weixin_title = result.kefu_info.kefu_weixin_title;
        data.qq_title = result.kefu_info.qq_title;
        data.kefu_weixin_qrcode = result.kefu_info.kefu_weixin_qrcode;


        data.facebook_name = result.kefu_info.facebook_name;
        data.facebook_link = result.kefu_info.facebook_link;
        data.line_name = result.kefu_info.line_name;
        data.email = result.kefu_info.email;
        data.qq_group = result.kefu_info.qq_group;
        data.qq_group_key = result.kefu_info.qq_group_key;
        data.kefu_weixin = result.kefu_info.kefu_weixin;
        data.kefu_weixin_url = result.kefu_info.kefu_weixin_url;
        data.exists_general_weixin = result.kefu_info.exists_general_weixin;

        general.faqGroups = result.faq;

      })
      .catch(err => {
          Dialog.error(err.message);
        });
  }
  useEffect(() => {
    if (props.active && !init) {
      load();
    }
  }, [props.active, init]);

  const [faq, setFaq] = useState(null);

//   const downFiles = () => {
//     // 创建隐藏的可下载链接
//     var eleLink = document.createElement('a');
//     eleLink.download = "vip.png";
//     eleLink.style.display = 'none';
//     // 字符内容转变成blob地址
//     var blob = new Blob([data.kefu_weixin_qrcode]);
// 　　 // 简单的理解一下就是将一个file或Blob类型的对象转为UTF-16的字符串，并保存在当前操作的document
//     eleLink.href = URL.createObjectURL(blob);
//     // 触发点击
//     document.body.appendChild(eleLink);
//     eleLink.click();

//     setTimeout(()=>{
//       window.location.href = 'weixin://';
//     }, 3000)
//     // 然后移除
//     document.body.removeChild(eleLink);
//   }
  const qqHref = () => {
    // window.location.href = 'mqqwpa://im/chat?chat_type=wpa&uin=' + data.qq + '&version=1&src_type=web&web_src=qq.com';
    // setTimeout(()=>{
    //   window.location.href = 'tim://im/chat?chat_type=wpa&uin=' +data.qq + '&version=1&src_type=web&web_src=qq.com';
    // }, 1000)
  }

  const qqGroupHref = () => {
    window.location.href = 'mqqwpa://im/chat?chat_type=wpa&uin=' + data.qq_group + '&version=1&src_type=web&web_src=qq.com';
    window.location.href = 'tim://im/chat?chat_type=wpa&uin=' +data.qq_group + '&version=1&src_type=web&web_src=qq.com';
  }

  // console.log(app);

  return <div
    className="service-index"
    // style={{backgroundImage: 'url(/resources/service/bg.png)'}}
  >
    <div className="service-title">客服中心</div>
    <div className="service-container">
      {/* <div className="service-info">
        <div className="info">在使用遇到任何问题<br/>请联系在线客服</div>
        <div className="time">在线时间：{data.time}</div>
      </div> */}
      
      {data.weixin ? 
      <div className="service-account">
        <div className="title">关注公众号联系在线客服</div>
        <div className="des1">打开微信搜索“{data.weixin}”公众号，并关注</div>
        <CopyToClipboard text={data.weixin} onCopy={() => Dialog.info('已复制公众号', () => window.location.href = 'weixin://')}>
        <div className="copy-number"><img src="/resources/service/account.png" /></div>
        </CopyToClipboard>
        <div className="des2">关注公众号更多福利消息不再错过！</div>
      </div>
      :null}

      {/* <div className="service-contact">
        <CopyToClipboard text={data.qq} onCopy={() => {
          Dialog.info('已复制QQ号', () => window.location.href = 'mqqwpa://im/chat?chat_type=wpa&uin=' + data.qq + '&version=1&src_type=web&web_src=qq.com');
        }}>
          <div className="item">
            <div className="icon" style={{backgroundImage: 'url(/resources/service/qq.png)'}}/>
            <div className="name">QQ在线客服</div>
            <div className="value">{data.qq}</div>
          </div>
        </CopyToClipboard>
        <CopyToClipboard text={data.weixin} onCopy={() => Dialog.info('已复制公众号', () => window.location.href = 'weixin://')}>
          <div className="item">
            <div className="icon" style={{backgroundImage: 'url(/resources/service/weixin.png)'}}/>
            <div className="name">微信公众号</div>
            <div className="value">{data.weixin}</div>
          </div>
        </CopyToClipboard>
      </div> */}
      {data.is_private_kefu ?
      <div className="service-vip-tips" style={{backgroundImage: 'url(/resources/service/tips-bg.png)'}}>
        <div className="tip-av"><img src="/resources/service/tips-av.png" /></div>
        <div className="tip-des">
          恭喜您成为尊贵的VIP用户，现已为您提供专属私人经理1对1服务特权，请及时添加获取更多福利优惠！
        </div>
      </div>
      :null}
      <div className="service-bt">{data.qq ? <>{data.is_private_kefu ? '私人经理' : '在线客服'}<span>在线时间：{data.time}</span> </> : null}</div> 
      <div className="service-customer">
        {data.is_private_kefu ?<>
        <dl>
          <dt>
            <div className="full">
              <div className="icon" style={{backgroundImage: 'url(/resources/service/new-weixin.png)'}}>
                <div className="recommend">
                  <img src="/resources/service/recommend.png" />
                </div>
              </div>
              <div className="text">{data.kefu_weixin_title}</div>
              <div className="c-info"></div>
            </div>
            {/* <div onClick={()=>setCustromState(true)} className="c-btn">去撩她</div> */}
            <div onClick={()=>window.location.href = data.kefu_weixin_url} className="c-btn">去撩她</div>
          </dt>
        </dl>
        
        {data.qq ?
        <dl>
          <dt>
            <div className="full">
              <div className="icon" style={{backgroundImage: 'url(/resources/service/new-qq.png)'}}/>
              <div className="text">{data.qq_title}</div>
              <div className="c-info">{data.qq}</div>
            </div>
            <div onClick={() => window.location.href = 'mqqwpa://im/chat?chat_type=wpa&uin=' + data.qq + '&version=1&src_type=web&web_src=qq.com'} className="c-btn">去撩她</div>
          </dt>
        </dl>
        : null}

        </>:<>
        {data.qq ?
        <dl>
          <dt>
            <div className="full">
              <div className="icon" style={{backgroundImage: 'url(/resources/service/new-qq.png)'}}/>
              <div className="text">QQ在线客服</div>
              <div className="c-info">{data.qq}</div>
            </div>
            <a href={'mqqwpa://im/chat?chat_type=wpa&uin=' + data.qq + '&version=1&src_type=web&web_src=qq.com'} className="c-btn">去撩她</a>
          </dt>
        </dl>
        : null}
        </>}
        {data.facebook_name ? 
        <dl>
          <dt>
            <div className="full">
              <div className="icon" style={{backgroundImage: 'url(/resources/service/facebook.png)'}}/>
              <div className="text">Facebook</div>
              <div className="c-info">{data.facebook_name}</div>
            </div>
            <a href={data.facebook_link} className="c-btn">去关注</a>
          </dt>
        </dl>
        : null}
        {data.line_name ? 
        <dl>
          <dt>
            <div className="full">
              <div className="icon" style={{backgroundImage: 'url(/resources/service/line.png)'}}/>
              <div className="text">LINE</div>
              <div className="c-info">{data.line_name}</div>
            </div>
            <CopyToClipboard text={data.line_name} onCopy={() => {Dialog.info('已复制账号')}}>
              <div className="c-btn">复制账号</div>
            </CopyToClipboard>
          </dt>
        </dl>
        : null}
        {data.emaill ? 
        <dl>
          <dt>
            <div className="full">
              <div className="icon" style={{backgroundImage: 'url(/resources/service/email.png)'}}/>
              <div className="text">电子邮件</div>
              <div className="c-info">{data.email}</div>
            </div>
            <CopyToClipboard text={data.email} onCopy={() => {Dialog.info('已复制邮箱')}}>
              <div className="c-btn">复制邮箱</div>
            </CopyToClipboard>
          </dt>
        </dl>
        : null}
        {data.qq_group ? 
        <dl>
          <dt>
            <div className="full">
              <div className="icon" style={{backgroundImage: 'url(/resources/service/qq.png)'}}/>
              <div className="text">QQ群</div>
              <div className="c-info">{data.qq_group}</div>
            </div>
            <a href={data.qq_group_key} className="c-btn">去加入</a>
            {/* <div onClick={qqGroupHref} className="c-btn">
            去加入
            </div> */}
          </dt>
        </dl>
        : null}
        {data.exists_general_weixin ?
        <dl>
          <dt>
            <div className="full">
              <div className="icon" style={{backgroundImage: 'url(/resources/service/weixin.png)'}}/>
              <div className="text"> {data.kefu_weixin_url ? data.kefu_weixin_title : '微信'}</div>
              <div className="c-info">{data.kefu_weixin_url ? '' : data.kefu_weixin}</div>

              <div className="tips">
                推荐
              </div>
            </div>

            {
              data.kefu_weixin_url ? 
              <div onClick={()=>window.location.href = data.kefu_weixin_url} className="c-btn">去撩她</div>
              :
              <CopyToClipboard text={data.kefu_weixin} onCopy={() => Dialog.info('已复制账号', () => window.location.href = 'weixin://')}>
                <div className="c-btn">复制账号</div>
              </CopyToClipboard>
            }
            {/* <CopyToClipboard text={data.kefu_weixin} onCopy={() => Dialog.info('已复制账号', () => window.location.href = 'weixin://')}>
              <div className="c-btn">复制账号</div>
            </CopyToClipboard> */}
          </dt>
        </dl>
        : null}
      </div>

      <div className="service-bt">常见问题</div>
      <div className="service-faq">
        {data.faqGroups.map((faqGroup, groupIndex) => <dl className={faq === groupIndex ? 'active' : ''}>
          <dt onClick={() => faqGroup.id != 'tel' && setFaq(groupIndex === faq ? null : groupIndex)}>
            <div className="full">
              <div className="icon" style={{backgroundImage: 'url(/resources/service/' + faqGroup.text + '.png)'}}/>
              <div className="text">{faqGroup.text}</div>
            </div>
            <div className="down "><img src="/resources/service/img9.png" /></div>
          </dt>
          <dd>
            {faqGroup?.items?.map((faq, questionIndex) => <Link to={'/faq?group=' + groupIndex + '&question=' + questionIndex}>{faq.q}</Link>)}
          </dd>
        </dl>)}
        {init ? 
        <dl>
          <dt>
            <div className="full">
              <div className="icon" style={{backgroundImage: 'url(/resources/service/tel.png)'}}/>
              <div className="text">投诉电话</div>
              <div className="tel-info">{data.tel}（仅处理投诉问题）</div>
            </div>
            <a href={data.tel ? ('tel:' + data.tel) : ''} className="tel-btn">拨打</a>
          </dt>
        </dl>
        : null}
      </div>
    </div>
    
    {/***弹框 */}
    {custromState ?
    <div className="wechat-pic-index">
      <div className="wechat-pic-site">
        <div className="wechat-pic-main">
          <div className="close" onClick={()=>setCustromState(false)}><img src="/resources/user/close.png" /></div>
          <div className="wechat-pic-title">
            添加企业微信
          </div>
          <div className="qrcode">
            <img src={data.kefu_weixin_qrcode} />
          </div>
          <div className="desc">
            手动截屏保存二维码至相册--点击微信右上角“+”扫一扫--进入相册选择二维码照片--添加至通讯录即可
          </div>
          <div className="btn">
            {/* <div onClick={downFiles}><img src="/resources/service/insert.png" /></div> */}
            {/* <a href={data.kefu_weixin_qrcode + '?response-content-type=application/octet-stream'} download="vip" className="vip-b">前往微信添加</a> */}

            <a href="weixin://" className="vip-b">前往微信添加</a>
          </div>
        </div>
      </div>
    </div>
    :null
    }

  </div>;
};