import general from "../../general";
import React, {useEffect, useState} from "react";
import {SyncOutlined, CheckCircleTwoTone, CloseCircleOutlined, PropertySafetyFilled} from '@ant-design/icons';
import {useInterval, useLockFn, useUnmount, useLocalStorageState} from "ahooks";
import URI from "urijs";
import {useLocation} from "react-router-dom";
import useActiveState from "../../lib/useActiveState";
import Dialog from "../../lib/Dialog";
import {Link} from "react-router-dom";
import {NavLink} from "../../lib/Navigation";
import {CSSTransition} from "react-transition-group";
import MobileDetect from "mobile-detect";
import useUrlState from "@ahooksjs/use-url-state";
import {CopyToClipboard} from "react-copy-to-clipboard/lib/Component";

export default ({game, setVipDownload}) => {
  const location = useLocation();

  let [agent] = useActiveState('agent');
  let [udid, setUdid] = useActiveState('udid');
  let [key] = useActiveState('key');
  let [app] = useActiveState('app');

  const [vipcard, setVipcard] = useState();
  const [installing, setInstalling] = useState();
  const [installingbak, setInstallingbak] = useState();
  const [state, setState] = useState();
  const [timer, setTimer] = useState();

  const [count, setCount] = useState(0);
  const [interval] = useState(500);

  const mobileDetect = new MobileDetect(navigator.userAgent);
  const [vestBag, setVestBag] = useLocalStorageState('is-vest-bag', 0);
  const [params, setParams] = useUrlState({}, {navigateMode: 'replace'});

  const [temporaryUrl, setTemporaryUrl] = useState(); //新的临时的支付链接 20220408
  const [vipRouterIndex, setVipRouterIndex] = useState(0);//新的支付弹框的选项 20220408
  const [progressNum, setProgressNum] = useState(0);//进度条数值
  const [isComplate, setIsDownComplate] = useState(false);
  const [isBaiFen, setIsBaiFen] = useState(false);

  const [downState, setDownState] = useState(false);
  const [downNewUrl, setDownNewUrl] = useState();
  const [gameExitConfirm, setGameExitConfirm] = useState(false);
  const [user, setUser] = useActiveState('user');
  const [price, setPrice] = useState(0);
  
  useInterval(
      () => {
        if (installing) {
          // setCount(count + 1);
          // let state = Math.floor(count / 10);
          // setState(state > 2 ? 2 : state);
          if(!isComplate) {
            let num = 0;
            let size = game?.game_size.ios_size.split(" ");

            // if(progressNum < 90) {
            //   if(size != undefined) {
            //     if(size[1] == 'GB') {
            //       num = 90 / (size[0] * 1024 / 6 * 2) + 0.01;
            //     } else {
            //       num = 90 / (size[0] / 3 * 2) + 0.01;
            //     }
            //   }
            // }
            if(progressNum <= 30) {
              if(size != undefined) {
                if(size[1] == 'GB') {
                  num = 90 / (size[0] * 1024 / 6 * 2) + 0.01;
                } else {
                  num = 90 / (size[0] / 3 * 2) + 0.01;
                }
                num = num * (parseInt(size[0]) < parseInt(100) ? 8.1 : 15.8);
              }
            } else if(progressNum <= 60 ) {
              if(size != undefined) {
                if(size[1] == 'GB') {
                  num = 90 / (size[0] * 1024 / 6 * 2) + 0.01;
                } else {
                  num = 90 / (size[0] / 3 * 2) + 0.01;
                }
                num = num * (parseInt(size[0]) < parseInt(100) ? 5.1 : 11.8);
              }
            } else if(progressNum <= 90 ) {
              if(size != undefined) {
                if(size[1] == 'GB') {
                  num = 90 / (size[0] * 1024 / 6 * 2) + 0.01;
                } else {
                  num = 90 / (size[0] / 3 * 2) + 0.01;
                }
                num = num * (parseInt(size[0]) < parseInt(100) ? 2.1 : 7.8);
              }
            } else if(progressNum <= 98 )  {
              if(size != undefined) {
                if(size[1] == 'GB') {
                  num = 90 / (size[0] * 1024 / 6 * 2) + 0.01;
                } else {
                  num = 90 / (size[0] / 3 * 2) + 0.01;
                }
              }
              num = num * 0.5;
            }
            
            setProgressNum(Math.floor((progressNum + num) * 100) /100);
          } else {
            // setProgressNum(100);
          }

          if(isBaiFen) {
            setProgressNum(100);
            setTimeout(()=>{setInstallingbak(false);setGameExitConfirm(false)}, 1000);
          }
        }
      },
      interval,
      {immediate: true},
  );

  useEffect(() => {
    install();
  }, [udid, installing]);
  useUnmount(() => timer && clearTimeout(timer));

  const loadUdid = async () => {
    Dialog.loading();
    await general.axios.post('/getKeyUdid', {key})
        .finally(() => {
          Dialog.close();
        })
        .then(res => {
          let {status, data} = res.data;
          if (!status.succeed) {
            setVipDownload(false);
            Dialog.error(status.error_desc);
            return;
          }
          setVipDownload(false);
          if (data.udid) {
            setUdid(data.udid);
            return;
          } else {
            // if((agent == 'wg3n' || agent == '9ahi' || agent == '19ab' || agent == '0zme' || agent == 's7y0' || agent == 'i1sd' || agent == '9d6f') && !udid && !params.agent_status) {
            //   let hrefUrl = URI('app://browser/')
            //           .addQuery('url', URI(window.location.href)
            //               .hash(
            //                   URI(location.pathname + location.search + location.hash)
            //                       .addQuery('key', key)
            //                       .addQuery('userid', localStorage.getItem('user_id'))
            //                       .addQuery('username', localStorage.getItem('username'))
            //                       .addQuery('token', localStorage.getItem('token'))
            //                       .addQuery('agent', agent)
            //                       .addQuery('agent_status', '3b8n')
            //                       .toString())
            //               .toString()
            //           )
            //           .toString();
              
            //   window.location.href = hrefUrl;
            //   return;
            // }
          }

          // window.location.href = URI('//api3.app.wakaifu.com/reactudid/install')
          //     .addQuery('url', URI(window.location.href)
          //         .hash(
          //             URI(location.pathname + location.search + location.hash)
          //                 .addQuery('agent', agent)
          //                 .addQuery('key', key)
          //                 .addQuery('userid', localStorage.getItem('user_id'))
          //                 .addQuery('username', localStorage.getItem('username'))
          //                 .addQuery('token', localStorage.getItem('token'))
          //                 .toString())
          //         .toString()
          //     )
          //     .addQuery('key', key)
          //     .toString();
          setTimeout(() => {
            window.location.href = 'https://api3.app.wakaifu.com/static/uuid/milu.mobileprovision';
          }, 2752);
          
        })
        .catch(err => {
          setVipDownload(false);
          Dialog.error(err.message);
        });
  }
  const loadGame = async () => {
    Dialog.loading();
    await general.axios.post('/base/game/getGameDetail', {game_id: game.game_id})
        .finally(() => {
          Dialog.close();
        })
        .then(res => {
          let {status, data} = res.data;
          if (!status.succeed) {
            setVipDownload(false);
            Dialog.error(status.error_desc);
            return;
          }
          // setGame(game => ({...game, isPayUser: data.game_info.isPayUser}));
          if (!data.game_info.isPayUser) {
            setVipcard(true);
          }

          //支付url重新赋值
          // if(data.game_info.supremePayUrl) {
          //   let payUrl = data.game_info.supremePayUrl + "&from=react";
          //   setPayUrl(payUrl + "&version=20220408&amount=" + game.vipSignMenu.items[0].price);
          //   setTemporaryUrl(payUrl);
          //   setPrice(game.vipSignMenu.items[0].price);
          // }
        })
        .catch(err => {
          setVipDownload(false);
          Dialog.error(err.message);
        });
  }
  // const load = useLockFn(async () => {
  //   if (!udid) {
  //     await loadUdid();
  //   } else {
  //     await loadGame();
  //   }
  // });
  const install = useLockFn(async () => {
    // if (game.vip_ios_url) {
    //   setVipDownload(false);
    //   if(agent == '1eaz') {
    //     let hrefUrl = URI('app://browser/')
    //           .addQuery('url', 'itms-services://?action=download-manifest&url=' + encodeURI(game.vip_ios_url)).toString();
    //     window.location.href = hrefUrl;
    //   } else {
    //     window.location.href = 'itms-services://?action=download-manifest&url=' + encodeURI(game.vip_ios_url);
    //   }
      
    //   return;
    // }

    // if (!udid || !game.isPayUser) {
    //   load();
    //   return;
    // }
    if (installing) {
      return;
    }
    setInstalling(true);
    setInstallingbak(true);
    let fn = async () => {
      await general.axios.post('//h5.api3.wakaifu.com/downSign', {
        agent,
        udid,
        game_id: game.maiyou_gameid,
        device_type: navigator.userAgent.match(/iPad/i) ? 2 : 1,
        device_version: mobileDetect.os() + mobileDetect.version('iPhone')
      }, {timeout: 60000 * 10})
          .then(res => {
            let {status, data} = res.data;
            if (!status.succeed) {
              Dialog.error(status.error_desc);
            } else {
              if (data.code === 1) {
                // setTimer(setTimeout(() => fn(), 3000));
              } else {
                if (data.code === 0) {
                  setIsDownComplate(true);
                  setIsBaiFen(true);

                  // setInstalling(false);
                  setDownNewUrl(data.url);
                  setDownState(true);
                  // if(agent == '1eaz') {
                  //   let hrefUrl = URI('app://browser/')
                  //         .addQuery('url', 'itms-services://?action=download-manifest&url=' + data.url).toString();
                  //   window.location.href = hrefUrl;
                  // } else {
                  //   window.location.href = 'itms-services://?action=download-manifest&url=' + data.url;
                  // }

                  // setInstalling(false);

                  // if(agent == '1eaz') {
                  //   let hrefUrl = URI('app://browser/')
                  //         .addQuery('url', 'itms-services://?action=download-manifest&url=' + data.url).toString();
                  //   window.location.href = hrefUrl;
                  // } else {
                  //   window.location.href = 'itms-services://?action=download-manifest&url=' + data.url;
                  // }
                  // window.location.href = 'itms-services://?action=download-manifest&url=' + data.url;
                } else if(data.code == 1200) {
                  // setInterceptState(true);
                  setVipDownload(false);
                } else {
                  Dialog.error(data.msg);
                  setInstallingbak(false);
                  setVipDownload(false);
                }
              }
            }
          })
          .catch(err => {
            setVipDownload(false);
            Dialog.error(err.message);
          });
    };
    await fn();
  });

  useEffect(()=>{
    if(downState) {
      setDownState(true);
      // if(agent == '1eaz') {
      //   let hrefUrl = URI('app://browser/')
      //         .addQuery('url', 'itms-services://?action=download-manifest&url=' + downNewUrl).toString();
      //   window.location.href = hrefUrl;
      // } else {
      //   window.location.href = 'itms-services://?action=download-manifest&url=' + downNewUrl;
      // }
      window.location.href = 'itms-services://?action=download-manifest&url=' + downNewUrl;
      setDownNewUrl(null);
    }
  }, [downState]);

  const canceldown = () => {
    setIsDownComplate(true);
    setGameExitConfirm(true);
  }

  const determine = () => {
    setVipDownload(false);
    setInstallingbak(false);
    setDownState(false);
    setGameExitConfirm(false);
  }

  return <>
    {agent != 'wg3n' && agent != "9ahi" && agent != "19ab" && agent != "0zme" && agent != "s7y0" && agent != "i1sd" && agent != "9d6f" && agent != "iey0" ? 
    <CSSTransition in={!udid} timeout={250} appear={true} unmountOnExit={true}>
      <div className="game-udid">
        <div className="container">
          <div className="head" onClick={() => setVipDownload(false)}>暂不安装</div>
          <div className="info">安装至尊版游戏前须安装苹果系统描述文件。安装时点击“<span className="free">允许</span>”，切勿点击“<span className="warn">忽略</span>”</div>
          <div className="btn" onClick={install}>我知道了</div>
        </div>
      </div>
    </CSSTransition>
    : null}
    {/* <NavLink to="/save?type=card&vip=1" className="btn" onClick={() => setVipDownload(false)}>购买</NavLink> */}
    {/* <CSSTransition in={vipcard} timeout={250} unmountOnExit={true}>
        <div className="game-vipcard-detail">
          <div className="vipcard-mask" />
          <div className="vipcard-container">
            <div className="vipcard-top">
              <div className="text">
                <div className="name">{game.vipSignMenu.title} <img src="/resources/game/tips-wh.png"  onClick={() => Dialog.alert('温馨提示', game.vipSignMenu.bottomTips)}/></div>
                <div className="desc">{game.vipSignMenu.comment1}</div>
                <div className="useful">
                  {game.vipSignMenu.useDesc}
                </div>
              </div>
              <div className="close" onClick={()=> {
                  setVipDownload(false);
                  setVipcard(false);
                  aplus_queue.push({
                    action: 'aplus.record',
                    arguments: ['CancelDownloadSupremeEdition', 'CLK', {age:user?.age, level : user?.vip_level, sex : user.sex == 0 ? '未知' : user.sex == 1 ? '男' : '女', username: localStorage.getItem('username'), gamename: game?.game_name, agent: agent}]
                  });
                }}>
                <img src="/resources/party/couple-close.png" />
              </div>
            </div>
            <div className="vipcard-tips">
              {game.vipSignMenu.comment2}
            </div>
            <div className={"vipcard-main " + (game.vipSignInfo.isRealBuy ? "renewal" : "")}>
              {
                game.vipSignMenu.items.map((item,index) => <div className={"item " + (index == vipRouterIndex ? 'active' : '')} onClick={()=>{setVipRouterIndex(index);setPayUrl(temporaryUrl + '&version=20220408&amount='+item.price);setPrice(item.price)}}>
                  {item.topLable ? 
                    <div className="good" style={{backgroundImage:'url(/resources/game/vipcard-good.png)'}}>
                      {item.topLable}
                    </div>
                    : null
                  }
                  <div className="price">￥<span>{item.price}</span></div>
                  <div className="tag">{item.buttonTitle}</div>
                  <div className="tip2">
                    <div className="intro">{item.comment1}</div>
                    <div className="object">{item.comment2}</div>
                  </div>
                </div>)
              }
            </div>
            <div className="vipcard-bottom" onClick={()=>{
                setVipDownload(false);
                setPaying(true);
                aplus_queue.push({
                  action: 'aplus.record',
                  arguments: ['PaySupremeEditionSuccess', 'CLK', {age:user?.age, level : user?.vip_level, sex : user.sex == 0 ? '未知' : user.sex == 1 ? '男' : '女', username: localStorage.getItem('username'), gamename: game?.game_name, agent: agent, price: price, is_first_pay:game.vipSignMenu.items.length == 3 ? true : false}]
                });
              }} >{game.vipSignMenu.items[vipRouterIndex].submitButtonTitle}</div>
          </div>
        </div>
    </CSSTransition> */}
    <CSSTransition in={installingbak} timeout={250} unmountOnExit={true}>
      {/* <div className="game-vipdownload">
        <div className="container">
          <div className={'item ' + (!state ? ' ing' : ' ed')}>
            <CheckCircleTwoTone className="icon normal" twoToneColor="#cccccc"/>
            <SyncOutlined spin className="icon ing"/>
            <CheckCircleTwoTone className="icon ed"/>
          </div>
          <div className={'item ' + (state > 0 ? ' ing' : ' ') + (state > 1 ? ' ed' : ' ')}>
            <CheckCircleTwoTone className="icon normal" twoToneColor="#cccccc"/>
            <SyncOutlined spin className="icon ing"/>
            <CheckCircleTwoTone className="icon ed"/>
          </div>
          <div className={'item ' + (state > 1 ? ' ing' : ' ') + (state > 2 ? ' ed' : ' ')}>
            <CheckCircleTwoTone className="icon normal" twoToneColor="#cccccc"/>
            <SyncOutlined spin className="icon ing"/>
            <CheckCircleTwoTone className="icon ed"/>
          </div>
          <div className="tips">请不要离开此页面并保持屏幕常亮</div>
        </div>
      </div> */}

      <div className="game-progress-bar">
        <div className="bar-mask" />
        <div className="bar-container">
          <div className="bar-top">
            <div className="name" onClick={()=>{canceldown()}}>取消</div>
          </div>

          <div className="bar-game-info">
            <div className="icon">
              <img src={game.game_image?.thumb}/>
            </div>
            <div className="name">
              {game.game_name}
            </div>
          </div>

          <div className="bar-gray">
            <div className="bar-progress" style={{width: progressNum + "%"}}></div>
            <span>下载中，勿退出{progressNum}%</span>
          </div>

          <div className="bar-tips">
            <div className="icon">
              <img src="/resources/game/game-question-mark.png"/>
            </div>
            <div className="name">
              请不要离开此页面并保持屏幕常亮 
            </div>
          </div>

          <div className="bar-udid">
            <span>UDID：{udid}</span>
            <CopyToClipboard text={udid} onCopy={() => Dialog.info('已复制')}><div className="copy-udid">复制</div></CopyToClipboard>
          </div>
        </div>
      </div>
    </CSSTransition>

    <CSSTransition in={gameExitConfirm} timeout={250} unmountOnExit={true}>
      <div className="game-exit-index">
        <div className="exit-site">
          <div className="exit-main">
            <div className="exit-title">
            温馨提示
            </div>
            <div className="info">
              <p>你确定要退出游戏下载吗</p>
            </div>
            <div className="exit-btn">
              <div className="item btn-about" onClick={() => {determine()}}>
                确定
              </div>
              <div className="item btn-confirm" onClick={() => {setIsDownComplate(false);setGameExitConfirm(false)}}>
                继续等待
              </div>
            </div>
          </div>
        </div>
      </div>
      </CSSTransition>
  </>;
}