import {useEffect, useState} from "react";
import {Link, useHistory} from "react-router-dom";
import general from "../../general";
import {CloseOutlined, EyeInvisibleOutlined, EyeOutlined} from "@ant-design/icons";
import {prod, url} from "../../env";
import {useLocalStorageState, useToggle, useLockFn} from "ahooks";
import useActiveState from "../../lib/useActiveState";
import Dialog from "../../lib/Dialog";
import URI from "urijs";
import MobileDetect from "mobile-detect";
import {NavLink} from "../../lib/Navigation";
import moment from "moment";
import {LeftOutlined} from "@ant-design/icons";

const mobileDetect = new MobileDetect(navigator.userAgent);
function randomNum(minNum, maxNum) {
  switch (arguments.length) {
    case 1:
      return parseInt(Math.random() * minNum + 1, 10);
      break;
    case 2:
      return parseInt(Math.random() * (maxNum - minNum + 1) + minNum, 10);
      break;
    default:
      return 0;
      break;
  }
}

let rand = randomNum(100000000, 999999999);

export default props => {
  const history = useHistory();
  const {aplus_queue} = window;
  const [app] = useActiveState('app');
  useEffect(() => {
    // props.setOperate(<Link to="login" className="account-register-btn">登录</Link>);
  }, []);
  const [focus, setFocus] = useState('');
  const [password, {toggle: togglePassword}] = useToggle(false);
  const [repeatPassword, {toggle: toggleRepeatPassword}] = useToggle(true);
  const [data, setData] = useState(!prod ? {
    username: rand,
    password: rand,
    // repeatPassword: rand,
  } : {});
  // const [agreement, setAgreement] = useLocalStorageState('agreement', false);
  const [agreement, setAgreement] = useState(true);
  const [registering, setRegistering] = useState(false);
  const [datas, setDatas] = useLocalStorageState('users', []);
  const [user, setUser] = useActiveState('user');
  const [agent] = useActiveState('agent');
  const [tk, setTk] = useActiveState('tk');
  const [tkUrl, setTkUrl] = useActiveState('tkUrl');
  const [tkStatus, setTkStatus] = useLocalStorageState('register-tk-status', 0);
  const [clueToken, setClueToken] = useActiveState('clueToken');
  const [ctStatus, setCtStatus] = useLocalStorageState('register-clue-token-status', 0);
  const [clickId, setClickId] = useActiveState('clickId');
  const [clickStatus, setClickStatus] = useLocalStorageState('register-clickid-status', 0);

  const [gameId] = useActiveState('gameId');
  const [gameInfo] = useActiveState('gameInfo');

  const register = () => {
    if (!data.username) {
      Dialog.error('请输入用户名');
      return;
    }
    if (!data.password) {
      Dialog.error('请输入密码');
      return;
    }
    if (!agreement) {
      Dialog.info('请先阅读并同意《用户协议》和《隐私政策》');
      return;
    }
    if (registering) {
      return;
    }

    // if(agent == 'ipw4') {
    //   Dialog.info("当前渠道有误，请重新打开链接");
    //   return;
    // }
    
    data.regtype = '5';
    // data.tk = tk;
    // data.tk_url = tkUrl;
    
    setRegistering(true);
    Dialog.loading();
    general.axios.post('/user/user/register', data)
        .finally(() => {
          setRegistering(false);
          Dialog.close();
        })
        .then(response => {
          let {status, data: result} = response.data;
          console.log()
          if (!status.succeed) {
            Dialog.error(status.error_desc);
          } else {
            localStorage.setItem('token', result.token);
            // localStorage.setItem('user_id', result.user_id);
            localStorage.setItem('username', data.username);
            // setDatas([...datas, {username: data.username, password: data.password}]);

            //检查是否有云设备
            general.axios.post(gameInfo.cloudappCheckAvailableUrl + "?gameid=" + gameId + "&username=" + localStorage.getItem('username'))
              .finally(() => {
                // Dialog.close();
              })
              .then(res => {
                let {status, data} = res.data;
                if (!status.succeed) {
                  Dialog.info(status.error_desc);
                } else {
                  if(data.isValid == '1') {
                    let hrefUrl = URI('/iframenogen')
                      .addQuery('url', URI(gameInfo.cloudappIndexUrl)
                      .addQuery('gameid', gameId)
                      .addQuery('username', localStorage.getItem('username'))
                      .addQuery('token', localStorage.getItem('token'))
                      .addQuery('gameicon', gameInfo.game_image?.thumb)
                      .addQuery('gamename', gameInfo.game_name)
                      .addQuery('nameremark', gameInfo.nameRemark)
                      .addQuery('location_url', encodeURIComponent(window.location.origin))
                      .addQuery('title', "aaaaa")
                      .addQuery('gamefullscreen', 1)
                    )
                    .toString();
                    history.push(hrefUrl)
                  } else {
                    Dialog.info('暂没有可用的云设备');
                  }
                }
              })
              .catch(err => {
                Dialog.error(err.message);
              });
            // Dialog.success('注册成功', () => general.goBack(true));
            // load_load()
            // setUser({isLogin: true, member_id: result.user_id});
          }
        })
        .catch(err => {
          Dialog.error(err.message);
        });
  }

  // const load = useLockFn(async () => {
  //     await general.axios.post('https://sdk.wakaifu.com/getRandUsername')
  //     .finally(() => {
  //     })
  //     .then(response => {
  //       let {status, data} = response.data;
  //       if (!status.succeed) {

  //       } else {

  //         console.log(data);
  //       }
  //     })
  //     .catch(err => {
  //         Dialog.error(err.message);
  //       });
  // })

  const load = () => {
    Dialog.loading();
    general.axios.get('https://sdk2.wakaifu.com/getRandUsername?origin=webbox')
      .finally(() => {
        Dialog.close();
      })
      .then(res => {
        let {status, data} = res.data;
        if (!status.succeed) {
          Dialog.info(status.error_desc);
        } else {
          setData({...data, username: data.username});
        }
      })
      .catch(err => {
        Dialog.error(err.message);
      });
  }

  useEffect(()=>{
    load();
  }, [props.active]);

  const random = () => {
    load();
  }

  // const load_load = useLockFn(async () => {
  //   await general.axios.post('/user/user/getMemberInfo')
  //     .finally(() => {
  //     })
  //     .then(response => {
  //       let {status, data} = response.data;
  //       if (!status.succeed) {

  //       } else {
  //         setUser({isLogin: true, ...data.member_info});
  //       }
  //     })
  //     .catch(err => {
  //         Dialog.error(err.message);
  //       });
  // })

  const updateAgreement = () => {
    let a = agreement == true ? false : (agreement == false ? true : false);
    setAgreement(a);
  }

  return <div className="account-register">
    <div className="body">
      {/* <div className="logo"><img src={app.applogo} alt=""/></div> */}
      <div className="logo">
        <img src={app.applogo} alt=""/>
      </div>
      <div className="text">
        <div className="name">
          {app.appname}
        </div>
        <div className="nameRemark">
          <span>
            {gameInfo?.nameRemark}
          </span>
        </div>
      </div>
      <div className="title">
        快速注册
      </div>
      {/* <div className="logo"><img src='/resources/user/login_logo.png' alt=""/></div> */}
      <div className="input-item">
        <div className="label">账号</div>
        <div className="input">
          <input name="username" type="text" value={data.username} placeholder="小写字母开头，6-16位用户名" autoComplete={false}
                 onFocus={e => setFocus('username')} onChange={e => setData({...data, username: e.target.value})}/>
          {focus === 'username' && data.username ?
              <CloseOutlined className="assist close" onClick={() => setData({...data, username: ''})}/> : null}
          <div className="randomnumber" onClick={()=>{random()}}>
            {app?.platformName != 'milu' ?
            (app?.platformName == 'huizhua' ? <img src="/resources/index/huiz_auth.png" />
            :
            <img src="/resources/index/hg_auth.png" />)
            : <img src="/resources/index/milu_auth.png" /> 
            }
          </div>
        </div>
      </div>
      <div className="input-item">
        <div className="label">密码</div>
        <div className="input">
          <input name="password" type={password ? 'password' : 'text'} value={data.password} placeholder="请输入6-18位登录密码"
                 onFocus={e => setFocus('password')} onChange={e => setData({...data, password: e.target.value})}/>
          {focus === 'password' && data.password ?
              <CloseOutlined className="assist close" onClick={() => setData(data => ({...data, password: ''}))}/> : null}
        </div>
      </div>

      <div className={'btn ' + (registering ? 'disable' : '') + (app?.platformName != 'milu' ? (app?.platformName == 'huizhua' ? ' huizhua ' : ' barter') : ' ')} onClick={register}>立即注册</div>
    </div>
    <div className={"account-agreement" + (app?.platformName != 'milu' ? (app?.platformName == 'huizhua' ? ' huizhua ' : ' barter') : ' ')}>
      <label>
        <span className={'agreement' + (agreement ? ' checked' : '')}  onClick={updateAgreement}/>
        登录注册代表您同意
        <NavLink to={(() => {
            let url = URI('//api.gamebox.i52wan.com/userAgreement')
                .addQuery('title', '用户协议')
                .addQuery('channel', agent)
                .addQuery('device_type', (mobileDetect.os() == 'iOS'?10:20))
                .toString();
              return url;
            })()}
            iframe={{title: '《用户协议》'}}
            user={false}
          >《用户协议》</NavLink>
        <NavLink to={(() => {
            let url = URI('//api.gamebox.i52wan.com/privacyPolicy')
                .addQuery('title', '隐私政策')
                .addQuery('channel', agent)
                .addQuery('device_type', (mobileDetect.os() == 'iOS'?10:20))
                .toString();
              return url;
            })()}
            iframe={{title: '《隐私政策》'}}
            user={false}
          >《隐私政策》</NavLink>
        <br/>
      </label>
    </div>

    <div className="head-oper">
      <div className="qa-back" onClick={() => general.history.goBack()}><LeftOutlined/></div>
      {/* <Link to={'/?gameid=' + gameInfo.maiyou_gameid} className="qa-back">
        <LeftOutlined/>
      </Link> */}
      <Link to="/qa" className="qa-text">
        常见问题？
      </Link>
    </div>

  </div>;
};