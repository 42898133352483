import React, {useEffect, useState, useCallback} from "react";
import "swiper/swiper-bundle.css"
import 'video-react/dist/video-react.css';
import '../css/css.css';
import {screenRouter, navigationRouter} from "../router";
import URI from "urijs";
import general, {redux} from "./../general";
import {NavLink, NavView} from "../lib/Navigation";
import {useLocalStorageState, useMount, useTimeout, useLockFn} from "ahooks";
import ActiveContext from "../lib/ActiveContext";
import useActiveState from "../lib/useActiveState";
import $, { data } from "jquery";
import {state} from "../lib/reducer";
import {useSelector} from "react-redux";
import Dialog from "../lib/Dialog";
import Image from "../lib/Image";
import {Link, useHistory, useLocation} from "react-router-dom";
import useUrlState from "@ahooksjs/use-url-state";
import moment from "moment";
import MobileDetect from "mobile-detect";

const mobileDetect = new MobileDetect(navigator.userAgent);

export default () => {
  let history = useHistory();
  let location = useLocation();
  const [params, setParams] = useUrlState({}, {navigateMode: 'replace'});
  useMount(() => {
    general.history = history;
    general.location = location;
  });

  const [active, setActive] = useLocalStorageState('active', []);
  const {aplus_queue} = window;

  const {theme} = useSelector(state);
  let [agent, setAgent] = useActiveState('agent');
  let [udid, setUdid] = useActiveState('udid');
  let [key, setKey] = useActiveState('key');
  let [app, setApp] = useActiveState('app');
  let [gameInfo, setGameInfo] = useActiveState('gameInfo');

  useMount(async () => {
    
    params.udid && setUdid(udid = params.udid);
    params.key && setKey(key = params.key);

    if(key && (!udid || udid == 'null')) {
      general.axios.post('/getKeyUdid', {headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
        },key})
        .then(res => {
          let {status, data} = res.data;
          if (!status.succeed) {
            Dialog.error(status.error_desc);
            return;
          }
          
          if (data.udid) {
            setUdid(udid = data.udid);
          }
        })
        .catch(err => {
          Dialog.error(err.message);
        });
    }
  })

  // useMount(async () => {
  //   params.agent && setAgent(agent = params.agent);
  //   params.udid && setUdid(udid = params.udid);
  //   params.key && setKey(key = params.key);

  //   if (!key || app.agent !== agent) {
  //     await general.axios.post('/setKeyUdid', {headers: {
  //       'Content-Type': 'application/x-www-form-urlencoded'
  //       },agent})
  //       .then(res => {
  //         let {status, data} = res.data;
  //         if (!status.succeed) {
  //           Dialog.error(status.error_desc);
  //           return;
  //         }
  //         setKey(key = data.key);
  //         setParams({...params, key: data.key});
  //       })
  //       .catch(err => {
  //         Dialog.error(err.message);
  //       });
  //   }

  //   if (key && (app.key !== key || !app.udid)) {
  //     general.axios.post('/getKeyUdid', {headers: {
  //       'Content-Type': 'application/x-www-form-urlencoded'
  //       },key})
  //       .then(res => {
  //         let {status, data} = res.data;
  //         if (!status.succeed) {
  //           Dialog.error(status.error_desc);
  //           return;
  //         }
          
  //         setApp(app = {...app, ...data});
  //         if (data.udid) {
  //           setUdid(udid = data.udid);
  //         }
  //       })
  //       .catch(err => {
  //         Dialog.error(err.message);
  //       });
  //   }

  //   if (key) {
  //     aplus_queue.push({
  //       action: 'aplus.setMetaInfo',
  //       arguments: ['openid', key]
  //     });
  //   }
  // })
  
  // useEffect(() => {
  //   history.action !== 'POP' && urlLogin();
  // }, [params, history.action]);
  // useEffect(() => {
  //   params.udid && setUdid(params.udid);
  // }, [params.udid]);

  // console.log(vestBag);
  useEffect(() => {
    document.title = app.appname;
    $('meta[name=apple-mobile-web-app-title]').attr('content', app.appname);
    $('link[id=appleTouchIcon]').attr('href', app.appicon);
    // if (app.flash_screen) {
    //   [
    //     {width: 2048, height: 2732},
    //     {width: 1668, height: 2388},
    //     {width: 1536, height: 2048},
    //     {width: 1668, height: 2224},
    //     {width: 1620, height: 2160},
    //     {width: 1284, height: 2778},
    //     {width: 1170, height: 2532},
    //     {width: 1125, height: 2436},
    //     {width: 1242, height: 2688},
    //     {width: 828, height: 1792},
    //     {width: 1242, height: 2208},
    //     {width: 750, height: 1334},
    //     {width: 640, height: 1136},
    //   ].forEach(({width, height}) => {
    //     $('link[id="' + (width + '-' + height) + '"]').attr('href', app.flash_screen + (
    //       width === 1170 && height === 2532 ? '!iphone12pro' : ('!iphone_' + width + 'x' + height)
    //     ));
    //   });
    // }
  }, [app]);

  const [config, setConfig] = useActiveState('config');
  const [newNavLists, setNewNavLists] = useLocalStorageState('index-navlists', []);
  // useMount(() => {
  //   if(params.gameid) {
  //     general.axios.get('/base/common/getConfig')
  //       .then(res => {
  //         let {status, data} = res.data;
  //         if (status.succeed) {
  //           setConfig({...config, ...data});

  //           let arr1 = [];
  //           for(let i = 0; i < data.navList.length; i++){
  //             arr1.push(data.navList[i]);
  //           }

  //           setNewNavLists(arr1);
  //         }
  //       })
  //       .catch(err => {
  //         Dialog.error(err.message);
  //       });
  //   }
  // });

  const [navs, setNavs] = useState([]);
  const [action, setAction] = useState([]);
  const handleNavs = () => {
    if (general.callbacking) {
      return;
    }
    let uri = URI(window.location.hash.substring(1));
    setNavs(_navs => {
      let navs = [..._navs];
      let url = uri.pathname() + URI(uri.search()).removeQuery('callback').toString();
      let pathname = uri.pathname();

      let router = navigationRouter.find(item => item.path === pathname);
      if (router) {
        navs.reverse();
        let index = navs.findIndex(item => item.url === url);
        navs.reverse();
        if (index > 0) {
          while (navs[navs.length - 1].url !== url) {
            navs.pop();
          }
        }
        if (index === -1) {
          navs.push({...router, query: uri.query(true), url});
        }
      } else {
        navs = [];
      }
      navs = navs.map((item, index, items) => {
        item.index = 700 + index + 1;
        item.active = items.length - 1 === index;
        item.action = items.length - 1 === index ? history.action : null;
        return item;
      });
      console.log([...navs]);
      return [...navs];
    });
  };
  useEffect(() => handleNavs(), [history, location]);
  useMount(() => {
    redux.subscribe(() => {
      if (redux.getState().url) {
        console.log('location', {...location}, window.location.href);
        handleNavs();
      }
    });
    redux.subscribe(() => {
      let screen = redux.getState().screenRouter?.screen;
      if (screen) {
        history.replace(screen.replace('/', '/screen-'));
      }
    });
  });

  useTimeout(() => {
    if (!active.time || moment(active.time).format('YYYY-MM-DD') !== moment().format('YYYY-MM-DD')) {
      setActive({time: moment().valueOf()});
    }
  }, 2000);

  const [safeArea, setSafeArea] = useState();
  useEffect(() => {
    setSafeArea(parseInt($('.safeareatest').css('padding-top')) > 20 || parseInt($('.safeareatest').css('padding-bottom')) > 20);
  });

  const Ref = useCallback(node => {
    if (node) {
      node.addEventListener('touchmove', e => {
        e.preventDefault();
        e.stopPropagation();
      }, {passive: false});
    }
  }, []);


  let [gameId, setGameid] = useActiveState('gameId');
  let [username, setUsername] = useActiveState('username');
  let [token, setToken] = useActiveState('token');
  // let [userid, setUserid] = useActiveState('userid');
  let [isFirstAccess, setIsFirstAccess] = useActiveState('isFirstAccess');


  // let [boxadGameId, setBoxadGameId] = useActiveState('boxadGameId');
  const [startState, setStartState] = useLocalStorageState('startState', true);
  const [refrechState, setRefrechState] = useLocalStorageState('startState', []);
  
  useEffect(() => {
    setStartState(true);
    localStorage.setItem('gameId', params.gameid);
    // localStorage.setItem('pageState', );
    params.gameid && setGameid(gameId = params.gameid);
    // if(params.gameid) {
    //   getInfo();
    //   if( (!document.referrer || (document.referrer && document.referrer.match(/:\/\/(.[^/]+)/).input ==  window.location.origin + '/')) && !window.navigator.standalone && refrechState != params.gameid) {
    //     setRefrechState(params.gameid);
    //     window.location.reload();
    //   }
    // } else {
    //   setInit(false)
    // }

    getInfo();
    // Dialog.alert(document.referrer);
    if( (!document.referrer || (document.referrer && document.referrer.match(/:\/\/(.[^/]+)/).input ==  window.location.origin + '/')) && !window.navigator.standalone && refrechState != params.gameid && params.gameid) {
    // if( (!document.referrer || (document.referrer && document.referrer.match(/:\/\/(.[^/]+)/).input ==  window.location.origin + '/')) && !window.navigator.standalone && (localStorage.getItem('boxadGameId') && localStorage.getItem('boxadGameId') != 'undefined' &&  localStorage.getItem('boxadGameId') != params.gameid) && params.gameid) {
      setRefrechState(params.gameid);
      // setBoxadGameId(boxadGameId = params.gameid);
      localStorage.setItem('boxadGameId', params.gameid);
      window.location.reload();
    }
  }, [params.gameid]);

  useEffect(() => {
    if(startState) {
      if(localStorage.getItem('username') && localStorage.getItem('username') != 'undefined') {

      } else {
        localStorage.setItem('username', params.username);
        params.username && setUsername(username = params.username);
      }
      // localStorage.setItem('username', params.username);
      // params.username && setUsername(username = params.username);
    }
  }, [params.username]);

  useEffect(() => {
    if(startState) {
      if(localStorage.getItem('token') && localStorage.getItem('token') != 'undefined') {

      } else {
        localStorage.setItem('token', params.token);
        params.token && setToken(token = params.token);
      }
      // localStorage.setItem('token', params.token);
      // params.token && setToken(token = params.token);
    }
  }, [params.token]);

  useEffect(() => {
    if(startState) {
      // localStorage.setItem('user_id', params.user_id);
      if(localStorage.getItem('user_id') && localStorage.getItem('user_id') != 'undefined') {

      } else {
        localStorage.setItem('user_id', params.user_id);
        // params.user_id && setUserid(userid = params.user_id);
      }
    }
    // localStorage.setItem('user_id', params.user_id);
    // params.user_id && setUserId(userId = params.user_id);
  }, [params.user_id]);

  useEffect(() => {
    params.agent && setAgent(agent = params.agent);
  }, [params.agent]);

  // console.log(gameId);
  // console.log(userName);
  // console.log(token);

  // useEffect(()=>{
  //   if(params.gameid && params.username && params.token) {
  //     window.location.href="https://sdk2.wakaifu.com/ysy?gameid=" + params.gameid + "&username=" + params.username + "&token=" + params.token + "&userid=11692250&origin=react&location_url=" + encodeURIComponent(window.location.origin);
  //   }
  // }, [params.gameid, params.username, params.token]);

  // useEffect(() => {
  //   if(params.gamename && params.icon) {
  //     let data = {};
  //     data.appname = params.gamename;
  //     data.appicon = params.icon;
  //     data.applogo = params.icon
  //     data.gameid = params.gameid

  //     setApp(app = {...app, ...data});
  //   }
  // }, []);

  const [init, setInit] = useState(false);

  const getInfo = (parms = {}) => {
    // if (loading) {
    //   return;
    // }
    // selTestAgent();
    // setLoading(true);
    // Dialog.loading();
    let data = {};

    if (localStorage.getItem('gameid')) {
      // data.game_id = localStorage.getItem('gameid');
      data.maiyou_gameid = localStorage.getItem('gameid');
    } else if (gameId) {
      data.maiyou_gameid = gameId;
    } else if(params.gameid){
      // data.game_id = params.gameid;
      data.maiyou_gameid = params.gameid;
    } else if(app.gameid) {
      data.maiyou_gameid = app.gameid;
    }

    if(!data.maiyou_gameid) {
      Dialog.error('缺少参数', 1, () => history.goBack);
      return;
    }

    general.axios.post('/base/game/getCloudGameInfo', data)
      .finally(() => {
        // setLoading(false);
        Dialog.close();
      })
      .then(res => {
        let {status, data} = res.data;
        if (!status.succeed) {
          Dialog.error(status.error_desc);
        } else {
          let game = {...data, ...data.game_info};
          setGameInfo(game);
          setInit(true);

          // console.log(game.game_name);

          let dataapp = {};
          dataapp.appname = game.game_name;
          dataapp.appicon = game.game_image?.thumb;
          dataapp.applogo = game.game_image?.thumb;
          dataapp.gameid = game.maiyou_gameid;
          dataapp.platformName = data.platformName;

          // setApp(app = {});
          setApp(app = {...app, ...dataapp});
        }
      })
      .catch(err => {
        Dialog.error(err.message);
      });
  }
  
  // console.log("123");
  // console.log(safeArea);
  // console.log(document.body.scrollHeight > (window.innerHeight || document.documentElement.clientHeight));
  // console.log("456");

  // Dialog.info(document.referrer);

  return <div> 
    {
      init ?
      window.navigator.standalone && document.referrer && document.referrer.match(/:\/\/(.[^/]+)/).input!= window.location.origin + '/' ?
      // window.location.hostname == 'localhost' ?
        <>
        {app?.platformName == 'milu' ?
          <div className="bookmarks">
            
            <img src="/resources/index/bookmarks-one.png" />
            <div className="jt">
              <img src="/resources/index/bookmarks-two.png" className="small-jt"/>
            </div>
          </div>
        :
        (
        app?.platformName == 'huizhua' ? 

        <div className="bookmarks">
            
            <img src="/resources/index/hz-bookmarks-one.png" />
            <div className="jt">
              <img src="/resources/index/hz-bookmarks-two.png" className="small-jt"/>
            </div>

          </div>
        :
          <div className="bookmarks">
            
            <img src="/resources/index/b-bookmarks-one.png" />
            <div className="jt">
              <img src="/resources/index/b-bookmarks-two.png" className="small-jt"/>
            </div>

          </div>)
        }
        </>
      :
    
    
      <div className={'root' + (safeArea ? ' root-hassafearea' : ' root-nosafearea') + ' theme-' + theme}>
        <div className="safeareatest"/>
        {
          !window.navigator.standalone
          && mobileDetect.is('iPhone')
          && mobileDetect.userAgents() == 'Safari'
          && window.location.hostname !== 'localhost'
          && !window.location.hostname.startsWith('192.168.')
          && !window.location.hostname.startsWith('127.0.')
          && (agent !== '03a1')
          && !params.debug
          && navs.length === 0
            ? 
              // <div className="desktop-index" style={{background:'#fff'}}>
              //   <div className="desktop-body" style={{display:'flex',alignItems:'center'}}>
              //     <img src="https://static-cdn.app.99maiyou.com/trade/9b4415fa8d1524a78e728c88a12f8915.png!trade_thumb" />
              //     <div className="head" style={{background:'#ffff', color:'#282828'}}>
              //       首页
              //     </div>
              //   </div>
              //</div>
                <div className="desktop-tips" ref={Ref} >
                  <div className="container">
                    <div className="entity">
                      <div className="top">
                        <div className="icon"><Image src={app.appicon} alt=""/></div>
                        <div className="text">
                          <img src="/resources/new-desktop-tips-1.png" alt=""/>
                        </div>
                      </div>
                      <img src="/resources/new-desktop-tips-5.png" alt=""/>
                      <div className="body">
                        <div className="icon"><Image src={app.appicon} alt=""/></div>
                        <div className="info">
                          <div className="name">{app.appname}</div>
                          <div className="url">{window.location.href}</div>
                        </div>
                      </div>
                      <img src="/resources/new-desktop-tips-3.png" alt=""/>
                      <div className="end-new">
                        <div className="icon">
                          <img src="/resources/new-desktop-tips-4.png" alt=""/>
                        </div>
                        <div className="text">
                          回到手机桌面，点击【<span>{app.appname}</span>】即可进入
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

            : null
        }
        <div className={'screen-index ' + (navs.length === 0 ? 'active' : '')}>
          <div className="screen-body">
            {screenRouter.map(item => <div className={'screen-body-item ' + item.id + ' ' + (location.pathname === item.path ? 'active' : '')}>
              <ActiveContext.Provider value={navs.length === 0 && location.pathname === item.path}>
                <item.component active={navs.length === 0 && location.pathname === item.path} action={action}/>
              </ActiveContext.Provider>
            </div>)}
          </div>
          {/* <div className="screen-menu width" key={location.pathname}>
            <div className="screen-menu-container">
              {screenRouter.map(item => {
                let Tag = item.user ? NavLink : Link;
                return <Tag to={item.path} replace className={'screen-menu-item ' + (location.pathname === item.path ? 'active' : '')}>
                  <div className="icon icon-dark" style={{backgroundImage: 'url(/resources/screen/' + item.id + '-dark.png)'}}/>
                  <div className="icon icon-highlight" style={{backgroundImage: 'url(/resources/screen/' + item.id + '-highlight.png)'}}/>
                  <div className="icon icon-white" style={{backgroundImage: 'url(/resources/screen/' + item.id + '-white.png)'}}/>
                  <div className="text">{item.title}</div>
                </Tag>
              })}
            </div>
          </div> */}
        </div>
        {navs.map(nav => <NavView {...nav}/>)}
      </div>
      : null
    }

  </div>
};